import { configureStore } from '@reduxjs/toolkit';
import menuListReducer from '../redux/features/menuList/menuListSlice';
import dateFilterReducer from './features/filterData/dateFilterSlice';
import checkboxFilterReducer from './features/checkboxFilterData/checkBoxFilterSlice';
import statusFilterReducer from './features/statusFilterData/statusFilterSlice';
import candidateReducer from './features/candidte/candidateSlice';
import radioGroupReducer from './features/radioGroup/radioGroupFilterSlice';

export const store = configureStore({
  reducer: {
    menuList: menuListReducer,
    filterData: dateFilterReducer,
    checkboxFilterDataSlice: checkboxFilterReducer,
    statusFilterDataSlice: statusFilterReducer,
    candidate: candidateReducer,
    radioGroupDataSlice: radioGroupReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
