import React from 'react';
const CvDashboard = (): JSX.Element => {
  return (
    <>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='24'
        height='24'
        viewBox='0 0 24 24'
      >
        <defs>
          <clipPath id='clip-Dashboard'>
            <rect width='24' height='24' />
          </clipPath>
        </defs>
        <g id='Dashboard' clipPath='url(#clip-Dashboard)'>
          <g
            id='Group_12267'
            data-name='Group 12267'
            transform='translate(-162.5 315)'
          >
            <path
              id='Path_1683'
              data-name='Path 1683'
              d='M345.011,2707h-4.23a2.74,2.74,0,0,0-1.173.123,1.079,1.079,0,0,0-.486.49,2.73,2.73,0,0,0-.121,1.169v4.229a2.729,2.729,0,0,0,.121,1.169,1.081,1.081,0,0,0,.486.49,2.745,2.745,0,0,0,1.173.122h4.23a2.745,2.745,0,0,0,1.173-.122,1.081,1.081,0,0,0,.486-.49,2.73,2.73,0,0,0,.121-1.169v-4.229a2.732,2.732,0,0,0-.121-1.169,1.079,1.079,0,0,0-.486-.49A2.74,2.74,0,0,0,345.011,2707Z'
              transform='translate(-174.5 -3020)'
              fill='none'
              stroke='#9c9baa'
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth='2'
            />
            <path
              id='Path_1684'
              data-name='Path 1684'
              d='M356.011,2707h-4.23a2.74,2.74,0,0,0-1.173.123,1.079,1.079,0,0,0-.486.49,2.73,2.73,0,0,0-.121,1.169v4.229a2.729,2.729,0,0,0,.121,1.169,1.081,1.081,0,0,0,.486.49,2.745,2.745,0,0,0,1.173.122h4.23a2.745,2.745,0,0,0,1.173-.122,1.081,1.081,0,0,0,.486-.49,2.729,2.729,0,0,0,.121-1.169v-4.229a2.73,2.73,0,0,0-.121-1.169,1.079,1.079,0,0,0-.486-.49A2.74,2.74,0,0,0,356.011,2707Z'
              transform='translate(-173.292 -3020)'
              fill='none'
              stroke='#9c9baa'
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth='2'
            />
            <path
              id='Path_1685'
              data-name='Path 1685'
              d='M356.011,2718h-4.23a2.74,2.74,0,0,0-1.173.123,1.077,1.077,0,0,0-.486.49,2.731,2.731,0,0,0-.121,1.169v4.229a2.732,2.732,0,0,0,.121,1.169,1.079,1.079,0,0,0,.486.49,2.74,2.74,0,0,0,1.173.123h4.23a2.74,2.74,0,0,0,1.173-.123,1.079,1.079,0,0,0,.486-.49,2.732,2.732,0,0,0,.121-1.169v-4.229a2.731,2.731,0,0,0-.121-1.169,1.078,1.078,0,0,0-.486-.49A2.74,2.74,0,0,0,356.011,2718Z'
              transform='translate(-173.292 -3018.792)'
              fill='none'
              stroke='#9c9baa'
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth='2'
            />
            <path
              id='Path_1686'
              data-name='Path 1686'
              d='M345.011,2718h-4.23a2.74,2.74,0,0,0-1.173.123,1.077,1.077,0,0,0-.486.49,2.731,2.731,0,0,0-.121,1.169v4.229a2.732,2.732,0,0,0,.121,1.169,1.079,1.079,0,0,0,.486.49,2.74,2.74,0,0,0,1.173.123h4.23a2.74,2.74,0,0,0,1.173-.123,1.079,1.079,0,0,0,.486-.49,2.733,2.733,0,0,0,.121-1.169v-4.229a2.732,2.732,0,0,0-.121-1.169,1.078,1.078,0,0,0-.486-.49A2.74,2.74,0,0,0,345.011,2718Z'
              transform='translate(-174.5 -3018.792)'
              fill='none'
              stroke='#9c9baa'
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth='2'
            />
          </g>
        </g>
      </svg>
    </>
  );
};
export default CvDashboard;
