import React from 'react';

const CvOrganization = (): JSX.Element => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="20"
            viewBox="0 0 22 20"
        >
            <path
                id="Path_13773"
                data-name="Path 13773"
                d="M476,1059h4.8a4.928,4.928,0,0,1,2.108.22,2.018,2.018,0,0,1,.874.87,4.986,4.986,0,0,1,.218,2.11v6.8m-8,0v-14.8a4.986,4.986,0,0,0-.218-2.11,2.018,2.018,0,0,0-.874-.87,4.928,4.928,0,0,0-2.108-.22h-3.6a4.928,4.928,0,0,0-2.108.22,2.018,2.018,0,0,0-.874.87,4.986,4.986,0,0,0-.218,2.11V1069m19,0H465m4.5-14h3m-3,4h3m-3,4h3"
                transform="translate(-464 -1050)"
                fill="none"
                stroke="#9c9baa"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
            />
        </svg>
    );
};
export default CvOrganization;
