import React from 'react';

const CvJobPositions = (): JSX.Element => {
  return (
    <>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='22'
        height='22'
        viewBox='0 0 22 22'
      >
        <defs>
          <clipPath id='clip-Dashboard'>
            <rect width='24' height='24' />
          </clipPath>
        </defs>
        <g
          id='Group_11801'
          data-name='Group 11801'
          transform='translate(-59.136 -425.198)'
        >
          <rect
            id='Rectangle_20410'
            data-name='Rectangle 20410'
            width='20'
            height='15.813'
            rx='2.771'
            transform='translate(60.136 430.386)'
            fill='none'
            stroke='#9c9baa'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='2'
          />
          <path
            id='Path_12794'
            data-name='Path 12794'
            d='M8.033,14.343A6.991,6.991,0,0,1,1.039,7.354'
            transform='translate(59.097 425.925)'
            fill='none'
            stroke='#9c9baa'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='2'
          />
          <path
            id='Path_12795'
            data-name='Path 12795'
            d='M21.627,7.354h0a6.991,6.991,0,0,1-6.994,6.989'
            transform='translate(58.508 425.925)'
            fill='none'
            stroke='#9c9baa'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='2'
          />
          <path
            id='Path_12796'
            data-name='Path 12796'
            d='M16.857,5.227H6.979V3.232A2.194,2.194,0,0,1,9.173,1.039h5.49a2.193,2.193,0,0,1,2.194,2.193Z'
            transform='translate(58.644 425.159)'
            fill='none'
            stroke='#9c9baa'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='2'
          />
          <rect
            id='Rectangle_20411'
            data-name='Rectangle 20411'
            width='6.011'
            height='3.523'
            transform='translate(67.13 438.521)'
            fill='none'
            stroke='#9c9baa'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='2'
          />
        </g>
      </svg>
    </>
  );
};
export default CvJobPositions;
