import { createSlice } from '@reduxjs/toolkit';
export interface CheckboxInterface {
  Type?: string[];
  Group?: string[];
  Category?: string[];
  Job?: string[];
  JobTitle?: string[];
  Company?: string[];
  Status?: string[];
  Brand?: string[];
  GroupSearchString?: string;
  CategorySearchString?: string;
  CompanySearchString?: string;
  BrandSearchString?: string;
  JobSearchString?: string;
  JobTitleSearchString: string;
  Organization?: string[];
  OrganizationSearchString?: string;
  TypeSearchString?: string;
  [key: string]: any; // Needed to Defne any here because accesssing value of redux in checkbox component like this ( const filterDataFromRedux = useSelector((state: RootState) => state?.checkboxFilterDataSlice?.[dynamicName] );)
}

const initialState: CheckboxInterface = {
  Type: [],
  Group: [],
  Category: [],
  Job: [],
  JobTitle: [],
  Company: [],
  Status: [],
  Organization: [],
  Brand: [],
  GroupSearchString: '',
  CategorySearchString: '',
  CompanySearchString: '',
  JobSearchString: '',
  OrganizationSearchString: '',
  JobTitleSearchString: '',
  TypeSearchString: '',
  BrandSearchString: '',
};

export const checkboxFilterDataSlice = createSlice({
  name: 'checkboxFilter',
  initialState,
  reducers: {
    Type: (state, action) => {
      state.Type = action.payload.Type;
    },
    TypeSearchString: (state, action) => {
      state.TypeSearchString = action.payload;
    },
    Group: (state, action) => {
      state.Group = action.payload.Group;
    },
    GroupSearchString: (state, action) => {
      state.GroupSearchString = action.payload;
    },
    Category: (state, action) => {
      state.Category = action.payload.Category;
    },
    CategorySearchString: (state, action) => {
      state.CategorySearchString = action.payload;
    },
    Job: (state, action) => {
      state.Job = action.payload.Job;
    },
    JobSearchString: (state, action) => {
      state.JobSearchString = action.payload;
    },
    JobTitle: (state, action) => {
      state.JobTitle = action.payload.JobTitle;
    },
    JobTitleSearchString: (state, action) => {
      state.JobTitleSearchString = action.payload;
    },
    Company: (state, action) => {
      state.Company = action.payload.Company;
    },
    CompanySearchString: (state, action) => {
      state.CompanySearchString = action.payload;
    },
    Brand: (state, action) => {
      state.Brand = action.payload.Brand;
    },
    BrandSearchString: (state, action) => {
      state.BrandSearchString = action.payload;
    },
    Status: (state, action) => {
      state.Status = action.payload.Status;
    },
    Organization: (state, action) => {
      state.Organization = action.payload.Organization;
    },
    OrganizationSearchString: (state, action) => {
      state.OrganizationSearchString = action.payload;
    },
    clearAll: (state) => {
      state.Type = initialState.Type;
      state.Group = initialState.Group;
      state.Category = initialState.Category;
      state.Job = initialState.Job;
      state.JobTitle = initialState.JobTitle;
      state.Company = initialState.Company;
      state.Brand = initialState.Brand;
      state.Organization = initialState.Organization;
      state.Status = initialState.Status;
      state.CategorySearchString = initialState.CategorySearchString;
      state.GroupSearchString = initialState.GroupSearchString;
      state.JobSearchString = initialState.JobSearchString;
      state.JobTitleSearchString = initialState.JobTitleSearchString;
      state.CompanySearchString = initialState.CompanySearchString;
      state.OrganizationSearchString = initialState.OrganizationSearchString;
      state.BrandSearchString = initialState.BrandSearchString;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  Type,
  TypeSearchString,
  Group,
  GroupSearchString,
  Category,
  CategorySearchString,
  Job,
  JobSearchString,
  JobTitle,
  JobTitleSearchString,
  Company,
  CompanySearchString,
  Status,
  Organization,
  OrganizationSearchString,
  Brand,
  BrandSearchString,
  clearAll,
} = checkboxFilterDataSlice.actions;

export default checkboxFilterDataSlice.reducer;
