import { Dayjs } from 'dayjs';
import { createSlice } from '@reduxjs/toolkit';
export interface DateFilter {
  fromdate?: Dayjs | null;
  toDate?: Dayjs | null;
}

const initialState: DateFilter = {
  fromdate: null,
  toDate: null,
};

export const filterDataSlice = createSlice({
  name: 'dateFilter',
  initialState,
  reducers: {
    storeFilterDates: (state, action) => {
      state.fromdate = action.payload.fromdate;
      state.toDate = action.payload.toDate;
    },
    clearDate: (state) => {
      state.fromdate = initialState.fromdate;
      state.toDate = initialState.toDate;
    },
  },
});

// Action creators are generated for each case reducer function
export const { storeFilterDates, clearDate } = filterDataSlice.actions;

export default filterDataSlice.reducer;
