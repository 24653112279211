import { gql } from '@apollo/client';
import { META_FRAGMENT } from '../fragments/metaFragment';

export enum RoleEnum {
  SUPER_ADMIN = 'SUPER_ADMIN',
  ADMIN = 'ADMIN',
  MEGA_ADMIN = 'MEGA_ADMIN',
}

//This mutation is use for delete single or multiple team member from the list
export const DELETETEAMMEMBER = gql`
  mutation ($uuid: [String]!) {
    deleteTeamMember(input: { uuid: $uuid }) {
      meta {
        ...MetaFragment
      }
    }
  }
  ${META_FRAGMENT}
`;

//This Mutation is use for create a new team member
export const CREATETEAMMEMBER = gql`
  mutation (
    $firstName: String!
    $lastName: String!
    $email: String!
    $phoneNumber: String!
    $company: [String]!
    $job_id:[String]!
  ) {
    createTeamMember(
      input: {
        first_name: $firstName
        last_name: $lastName
        email: $email
        mobile_number: $phoneNumber
        company_id: $company
        job_id:$job_id
      }
    ) {
      data {
        first_name
        last_name
        uuid
      }
      meta {
        ...MetaFragment
      }
    }
  }
  ${META_FRAGMENT}
`;

//This Mutation is use for update team member details
export const EDITTEAMMEMBER = gql`
  mutation (
    $id: Int!
    $uuid: String!
    $firstName: String!
    $lastName: String!
    $phoneNumber: String!
    $company: [String]!
    $job_id:[String]!
  ) {
    editTeamMember(
      input: {
        id: $id
        uuid: $uuid
        first_name: $firstName
        last_name: $lastName
        mobile_number: $phoneNumber
        company_id: $company
        job_id:$job_id
      }
    ) {
      meta {
        ...MetaFragment
      }
    }
  }
  ${META_FRAGMENT}
`;

//This Mutation is use for change the status of team members
export const CHANGETEAMMEMBERSTATUS = gql`
  mutation ($uuid: String!, $status: StatusEnum!) {
    activeInactiveTeamMember(input: { uuid: $uuid, status: $status }) {
      meta {
        ...MetaFragment
      }
    }
  }
  ${META_FRAGMENT}
`;

export const CHANGEPASSWORD = gql`
  mutation ($uuid: String!, $password: String!, $confirmPassword: String!) {
    changePassword(
      input: {
        uuid: $uuid
        password: $password
        password_confirmation: $confirmPassword
      }
    ) {
      meta {
        ...MetaFragment
      }
    }
  }
  ${META_FRAGMENT}
`;


