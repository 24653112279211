import './styles/app.scss';
import React, { Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CompanyTypeEnum, ROUTES } from '@config/constant';
import Loader from '@components/common/loader';
const SignIn = React.lazy(() =>import('@views/signin/signin'))
const Registration = React.lazy(() =>import('@views/signin/registration'))
const CMSPageDetails = React.lazy(
  () => import('src/admin/cmsPage/cmsPageDetails')
);
// const QueGroup = React.lazy(() => import('@views/queGroup'));
// Containers
const DefaultLayout = React.lazy(() => import('@layout/DefaultLayout'));
const PublicLayout = React.lazy(() => import('@layout/PublicLayout'));
const CandidateLayout = React.lazy(() => import('@layout/CandidateLayout'));
const AdminLayout = React.lazy(() => import('@layout/AdminLayout'));
const NotFound = React.lazy(() => import('@views/404'));
const Dashboard = React.lazy(() => import('@views/dashboard'));
const Login = React.lazy(() => import('@views/login'));
const AdminLogin = React.lazy(() => import('src/admin/login'));
const ForgotPassword = React.lazy(() => import('@views/forgotPassword'));
const StyleGuid = React.lazy(() => import('@views/styleGuid'));
const ResetPassword = React.lazy(() => import('@views/resetPassword'));
const JobPosition = React.lazy(() => import('@views/jobPosition'));
const AddNewJobPosition = React.lazy(
  () => import('@views/jobPosition/AddNewJobPosition')
);
const QueCategory = React.lazy(() => import('@views/queCategory'));
const Jobs = React.lazy(() => import('@views/jobs'));
const JobDetail = React.lazy(() => import('@views/jobs/JobDetail'));
const Candidate = React.lazy(() => import('@views/candidate'));
const CandidateDetails = React.lazy(
  () => import('@views/candidate/candidateDetails')
);
const Company = React.lazy(() => import('@views/company'));
const Team = React.lazy(() => import('@views/team'));
const Settings = React.lazy(() => import('@views/settings'));
const Opportunities = React.lazy(() => import('@views/opportunities'));
const CreateJob = React.lazy(() => import('@views/jobs/createJob'));
const UserProfile = React.lazy(() => import('@views/userProfile'));
const CandidateDashboard = React.lazy(() => import('@candidates/dashboard'));
const CandidateDetail = React.lazy(() => import('@candidates/candidateDetail'));
const IntroMessage = React.lazy(() => import('@candidates/introMessage'));
const TopTips = React.lazy(() => import('@candidates/topTips'));
const UploadingAnswer = React.lazy(() => import('@candidates/uploadingAnswer'));
const OutroVideo = React.lazy(() => import('@candidates/outroVideo'));
const QuestionList = React.lazy(() => import('@candidates/questionList'));
const Subscription = React.lazy(() => import('src/admin/subscription'));
const OrganizationHierarchy = React.lazy(
  () => import('src/admin/organizationHierarchy')
);
const Organization = React.lazy(() => import('src/admin/organization'));
const AddEditOrganization = React.lazy(
  () => import('src/admin/organization/addEditOrganization')
);
const ScheduledDemo = React.lazy(() => import('src/admin/scheduledDemo'));
const QuestionBank = React.lazy(() => import('src/admin/queBank'));
const Reports = React.lazy(() => import('src/admin/reports'));
const CmsPage = React.lazy(() => import('src/admin/cmsPage'));
const AddEditCmsPage = React.lazy(
  () => import('src/admin/cmsPage/addEditCmsPage')
);
const CandidateInterviewDetail = React.lazy(
  () => import('@views/candidate/candidateInterviewDetails')
);
const TemplateManagement=React.lazy(()=>import('@views/templateManagement'))
const Invite =React.lazy(()=>import('@views/jobPostingInvite/jobPostingInvite'))
const CouponsManagement=React.lazy(()=>import('@views/coupansManagement'))

const Brand = React.lazy(() => import('@views/brand'));
const SubscriptionList = React.lazy(() => import('@views/subscriptionList'));
const SubscriptionSuccess = React.lazy(
  () => import('@views/subscriptionList/success')
);
import { init } from 'commandbar';
import { getAdminAuthToken, getAuthToken, getCurrentAdmin, getCurrentUser, getUserCompanyType } from '@config/helpers';
init(process.env.REACT_APP_COMMANDBAR_KEY ?? '');


function App () {
  const redirectToMainSite = () => {
    if (window.location.pathname === '/') {
      window.location.href = `${process.env.REACT_APP_MAIN_SITE_URL}`;
      return <Loader loading={true} />;
    }
  };

  if (getAdminAuthToken() || getAuthToken()) {
    const companyType = getUserCompanyType();
    let isCompany = true;
    if (companyType === CompanyTypeEnum.LOCATION) {
      isCompany = false;
    }
    const userData = getCurrentAdmin() || getCurrentUser();
    const loggedInUserId = userData?.adminLogin?.data?.user?.uuid ?? userData?.recruiterLogin?.data?.user.uuid;
    window?.CommandBar?.boot(loggedInUserId,{
      ['is_company']: isCompany
    });
  }

  /**
   * Authenticated Candidate Interview Routes
   * @returns
   */
  const candidatePreviewRoutes = () => {
    return (
      <Route path={`${ROUTES.candidatePreview}`} element={<CandidateLayout />}>
        <Route path={':uuid'} element={<CandidateDashboard />} />
        <Route
          path={`${ROUTES.candidateDetail}/:uuid`}
          element={<CandidateDetail />}
        />
        <Route index element={<CandidateDashboard />} />
        <Route path={`${ROUTES.topTips}/:uuid`} element={<TopTips />} />
        <Route
          path={`${ROUTES.candidateSampleQuestions}/:uuid`}
          element={<QuestionList />}
        />
        <Route
          path={`${ROUTES.introMessage}/:uuid`}
          element={<IntroMessage />}
        />
        <Route
          path={`${ROUTES.candidateMainQuestions}/:uuid`}
          element={<QuestionList />}
        />
        <Route path={`${ROUTES.outroVideo}/:uuid`} element={<OutroVideo />} />
        <Route path={ROUTES.uploadingAnswer} element={<UploadingAnswer />} />
      </Route>
    );
  };

  return (
    <>
      <ToastContainer autoClose={3000} />
      <Suspense fallback={<Loader loading={true}></Loader>}>
        <Routes>
          {/* Public routes */}
          <Route element={<PublicLayout />}>
          {/* <Route path={ROUTES.signin} element={<SignIn />} /> */}
          <Route path={ROUTES.registration} element={<Registration />} />
          <Route path={`/:companySlug/${ROUTES.registration}`} element={<Registration />} />
            {/* Organization Hierarchy Route - Start */}
            <Route
              path={`${ROUTES.orgHierarchySlugLevel1}/${ROUTES.login}`}
              element={<Login />}
            />
            <Route
              path={`${ROUTES.orgHierarchySlugLevel2}/${ROUTES.login}`}
              element={<Login />}
            />
            <Route
              path={`${ROUTES.orgHierarchySlugLevel3}/${ROUTES.login}`}
              element={<Login />}
            />
            <Route
              path={`${ROUTES.orgHierarchySlugLevel1}/${ROUTES.forgotPassword}`}
              element={<ForgotPassword />}
            />
            <Route
              path={`${ROUTES.orgHierarchySlugLevel2}/${ROUTES.forgotPassword}`}
              element={<ForgotPassword />}
            />
            <Route
              path={`${ROUTES.orgHierarchySlugLevel3}/${ROUTES.forgotPassword}`}
              element={<ForgotPassword />}
            />
            <Route
              path={`${ROUTES.orgHierarchySlugLevel1}/${ROUTES.resetPassword}/:${ROUTES.resetToken}/:${ROUTES.resetEmail}`}
              element={<ResetPassword />}
            />
            <Route
              path={`${ROUTES.orgHierarchySlugLevel2}/${ROUTES.resetPassword}/:${ROUTES.resetToken}/:${ROUTES.resetEmail}`}
              element={<ResetPassword />}
            />
            <Route
              path={`${ROUTES.orgHierarchySlugLevel3}/${ROUTES.resetPassword}/:${ROUTES.resetToken}/:${ROUTES.resetEmail}`}
              element={<ResetPassword />}
            />
            <Route
              path={`${ROUTES.orgHierarchySlugLevel1}`}
              element={<Navigate to={`${ROUTES.login}`} />}
            />
            <Route
              path={`${ROUTES.orgHierarchySlugLevel2}`}
              element={<Navigate to={`${ROUTES.login}`} />}
            />
            <Route
              path={`${ROUTES.orgHierarchySlugLevel3}`}
              element={<Navigate to={`${ROUTES.login}`} />}
            />
            <Route
              path={`${ROUTES.page500}`}
              element={<NotFound isDisplayBackButton={true} />}
            />
            {/* Organization Hierarchy Route - End */}
            {/* Need to comment below three routes once conform with deployment */}
            {/* <Route path={ROUTES.login} element={<Login />} /> */}
            {/* <Route path={ROUTES.forgotPassword} element={<ForgotPassword />} /> */}
            {/* <Route
              path={`${ROUTES.resetPassword}/:${ROUTES.resetToken}/:${ROUTES.resetEmail}`}
              element={<ResetPassword />}
            /> */}
            <Route
              path=''
              element={
                process.env.REACT_APP_DEFAULT_ROUTE_ADMIN ? (
                  <Navigate to={`/${ROUTES.admin}`} />
                ) : (
                  redirectToMainSite()
                )
              }
            />
            <Route path='/style-guide' element={<StyleGuid />} />
            <Route
              path={`${ROUTES.subscription}/:uuid`}
              element={<SubscriptionList isForView={false} />}
            />
            <Route
              path={`${ROUTES.subscription}/${ROUTES.success}`}
              element={<SubscriptionSuccess />}
            />
            <Route path={ROUTES.admin}>
              <Route path={ROUTES.login} element={<AdminLogin />} />
              <Route
                path={ROUTES.forgotPassword}
                element={<ForgotPassword />}
              />
              <Route
                path={`${ROUTES.resetPassword}/:${ROUTES.resetToken}/:${ROUTES.resetEmail}`}
                element={<ResetPassword />}
              />
            </Route>
            <Route
              path={ROUTES.admin}
              element={<Navigate to={`/${ROUTES.admin}/${ROUTES.login}`} />}
            />
          </Route>
          {/* Private routes */}
          <Route path={ROUTES.app} element={<DefaultLayout />}>
            <Route index element={<Dashboard />} />
            <Route path={ROUTES.dashboard} element={<Dashboard />} />
            <Route path={ROUTES.jobPosition}>
              <Route path={ROUTES.jobPosition} element={<JobPosition />} />
              <Route index element={<JobPosition />} />
              <Route
                path={ROUTES.addNewJobPosition}
                element={<AddNewJobPosition />}
              />
              <Route
                path={`${ROUTES.viewJobPosition}/:uuid`}
                element={<AddNewJobPosition />}
              />
              <Route
                path={`${ROUTES.editJobPosition}/:uuid`}
                element={<AddNewJobPosition />}
              />
            </Route>
            <Route path={ROUTES.brand}>
              <Route index element={<Brand />} />
            </Route>
            <Route path={ROUTES.queBank}>
              <Route index element={<QuestionBank />} />
              <Route path={ROUTES.queCategory} element={<QueCategory />} />
              {/* <Route path={ROUTES.queGroup} element={<QueGroup />} /> */}
            </Route>
            <Route path={ROUTES.jobs}>
              <Route index element={<Jobs />} />
            </Route>
            <Route
              path={`/${ROUTES.app}/${ROUTES.jobs}/${ROUTES.jobDetail}/:uuid`}
              element={<JobDetail />}
            />
            <Route
              path={`/${ROUTES.app}/${ROUTES.jobs}/${ROUTES.createJob}`}
              element={<CreateJob />}
            />
            <Route
              path={`/${ROUTES.app}/${ROUTES.jobs}/${ROUTES.createJobGeneral}`}
              element={<CreateJob />}
            />
            <Route
              path={`/${ROUTES.app}/${ROUTES.jobs}/${ROUTES.editJob}/:uuid`}
              element={<CreateJob />}
            />
              <Route
          path={`/${ROUTES.app}/${ROUTES.jobs}/${ROUTES.invite}/:uuid`}  element={<Invite />}
        />
            <Route path={ROUTES.candidate} element={<Candidate />} />
            <Route
              path={ROUTES.candidateDetails}
              element={<CandidateDetails />}
            />
            <Route
              path={`/${ROUTES.app}/${ROUTES.candidate}/${ROUTES.candidateDetails}/:uuid`}
              element={<CandidateDetails />}
            />
            <Route path={ROUTES.company} element={<Company />} />
            <Route path={ROUTES.team} element={<Team />} />


            <Route path={ROUTES.reports} element={<Reports />} />
            <Route path={ROUTES.settings} element={<Settings />} />
            <Route path={ROUTES.opportunities} element={<Opportunities />} />
            <Route path={ROUTES.userProfile} element={<UserProfile />} />
            <Route
              path={ROUTES.app}
              element={<Navigate to={`/${ROUTES.app}/${ROUTES.login}`} />}
            />
              <Route path={ROUTES.templateManagement} element={<TemplateManagement />} />
          </Route>
          {/* Candidate routes */}
          <Route path={`${ROUTES.candidate}`} element={<CandidateLayout />}>
            <Route index element={<CandidateDashboard />} />
            <Route path={':uuid'} element={<CandidateDashboard />} />
            <Route
              path={`${ROUTES.candidateDetail}/:uuid`}
              element={<CandidateDetail />}
            />
            <Route
              path={`${ROUTES.introMessage}/:uuid`}
              element={<IntroMessage />}
            />
            <Route path={`${ROUTES.topTips}/:uuid`} element={<TopTips />} />
            <Route
              path={`${ROUTES.candidateSampleQuestions}/:uuid`}
              element={<QuestionList />}
            />
            <Route
              path={`${ROUTES.candidateMainQuestions}/:uuid`}
              element={<QuestionList />}
            />
            <Route
              path={ROUTES.uploadingAnswer}
              element={<UploadingAnswer />}
            />
            <Route
              path={`${ROUTES.outroVideo}/:uuid`}
              element={<OutroVideo />}
            />
          </Route>
          {/* Candidate Private routes */}
          {candidatePreviewRoutes()}
          {/* Super admin routes */}
          <Route path={ROUTES.admin} element={<AdminLayout />}>
            <Route index element={<Dashboard />} />
            <Route path={ROUTES.dashboard} element={<Dashboard />} />
            <Route path={ROUTES.scheduledDemo} element={<ScheduledDemo />} />
            <Route path={ROUTES.cmsPage}>
              <Route index element={<CmsPage />} />
              <Route path={ROUTES.addCmsPage} element={<AddEditCmsPage />} />
              <Route
                path={`${ROUTES.editCmsPage}/:uuid`}
                element={<AddEditCmsPage />}
              />
            </Route>
            <Route path={ROUTES.jobs}>
              <Route index element={<Jobs />} />
              <Route
                path={`${ROUTES.jobDetail}/:uuid`}
                element={<JobDetail />}
              />
              <Route path={`${ROUTES.createJob}`} element={<CreateJob />} />
              <Route path={`${ROUTES.createJobGeneral}`} element={<CreateJob />} />
              <Route path={`${ROUTES.editJob}/:uuid`} element={<CreateJob />} />
              <Route
          path={`${ROUTES.invite}/:uuid`}  element={<Invite />}
        />
            </Route>
            <Route path={ROUTES.brand}>
              <Route index element={<Brand />} />
            </Route>
            {/* <Route path={ROUTES.queBank} element={<QuestionBank />} /> */}
            <Route path={ROUTES.queBank}>
              <Route index element={<QuestionBank />} />
              <Route path={ROUTES.queCategory} element={<QueCategory />} />
              {/* <Route path={ROUTES.queGroup} element={<QueGroup />} /> */}
            </Route>
            <Route path={ROUTES.reports} element={<Reports />} />
            <Route
              path={ROUTES.subscription}
              element={<SubscriptionList isForView={true} />}
            />
            <Route path={ROUTES.couponsManagement}>
              <Route index element={<CouponsManagement />} />
            </Route>
            <Route path={ROUTES.organization}>
              <Route index element={<Organization />} />
              <Route
                path={ROUTES.addOrganization}
                element={<AddEditOrganization />}
              />
              <Route

                path={`${ROUTES.editOrganization}/:uuid`}
                element={<AddEditOrganization />}
              />
              <Route
                path={`${ROUTES.viewOrganization}/:uuid`}
                element={<AddEditOrganization />}
              />
            </Route>
            <Route path={ROUTES.jobPosition}>
              <Route index element={<JobPosition />} />
              <Route path={ROUTES.jobPosition} element={<JobPosition />} />
              <Route
                path={ROUTES.addNewJobPosition}
                element={<AddNewJobPosition />}
              />
              <Route
                path={`${ROUTES.editJobPosition}/:uuid`}
                element={<AddNewJobPosition />}
              />
              <Route
                path={`${ROUTES.viewJobPosition}/:uuid`}
                element={<AddNewJobPosition />}
              />
            </Route>
            <Route
              path={ROUTES.organizationHierarchy}
              element={<OrganizationHierarchy />}
            />
            <Route path={ROUTES.team} element={<Team />} />
            <Route path={ROUTES.company} element={<Company />} />

            <Route path={ROUTES.adminProfile} element={<UserProfile />} />
            <Route path={ROUTES.candidate} element={<Candidate />} />
            <Route path={ROUTES.templateManagement} element={<TemplateManagement />} />

            <Route
              path={`/${ROUTES.admin}/${ROUTES.candidate}/${ROUTES.candidateDetails}/:uuid`}
              element={<CandidateDetails />}
            />
          </Route>
          <Route
            path={`${ROUTES.candidateDetails}/:uuid`}
            element={<CandidateInterviewDetail />}
          />
          <Route
            path={`/${ROUTES.cmsPage}/:uuid`}
            element={<CMSPageDetails />}
          />
          <Route path='*' element={<NotFound />} />
        </Routes>
      </Suspense>
    </>
  );
}

export default App;
