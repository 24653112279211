import statusMessage from '@components/common/statusMessage';
import statusTag from '@components/common/statusTag';
import {
  Cvjobpositions,
  Cvcompany,
  Cvdashboard,
  Cvreports,
  Cvsettings,
  Cvteam,
  Cvuser,
  Cvjobs,
  Cvquestion,
  Cvsubscription,
  Cvorganization,
  CvscheduleDemo,
  Cvcmspages,
  Cvhierarchy,
  Cvbrand,
} from '@components/icons';
import CvCoupon from '@components/icons/cvCoupon';
import CvTemplate from '@components/icons/cvTemplate';

import { MyWindow } from '@components/videoRecord/videoRecorder';
export const ROUTES = {
  login: 'login',
  app: 'app',
  dashboard: 'dashboard',
  forgotPassword: 'forgot-password',
  resetPassword: 'reset-password',
  resetToken: 'token',
  resetEmail: 'email',
  styleGuid: 'styleGuid',
  page500: '404',
  jobPosition: 'job-positions',
  addNewJobPosition: 'add-job-position',
  editJobPosition: 'edit-job-position',
  viewJobPosition: 'view-job-position',
  queCategory: 'question-category',
  videoTesting: 'videoTesting',
  queGroup: 'question-group',
  queBank: 'question-bank',
  jobs: 'jobs',
  editJob: 'edit-job',
  jobDetail: 'job-detail',
  candidate: 'candidate',
  candidateDetails: 'candidate-details',
  company: 'company',
  team: 'team',
  reports: 'reports',
  settings: 'settings',
  opportunities: 'opportunities',
  createJob: 'add-job',
  createJobGeneral: 'general-add-job',
  userProfile: 'user-profile',
  interview: 'interview',
  candidateDetail: 'details',
  introMessage: 'intro-message',
  topTips: 'top-tips',
  interviewStart: 'interview-start',
  startQuestion: 'start-question',
  uploadingAnswer: 'uploading-answer',
  outroVideo: 'outro-message',
  admin: 'admin',
  adminProfile: 'admin-profile',
  scheduledDemo: 'scheduled-demo',
  cmsPage: 'cms-pages',
  addCmsPage: 'add-cms-page',
  editCmsPage: 'edit-cms-page',
  subscription: 'subscriptions',
  organization: 'organizations',
  addOrganization: 'add-organization',
  editOrganization: 'edit-organization',
  viewOrganization: 'view-organization',
  organizationHierarchy: 'organization-hierarchy-control',
  candidateSampleQuestions: 'sample-questions',
  candidateMainQuestions: 'questions',
  brand: 'brand',
  orgHierarchySlugLevel1: ':organization',
  orgHierarchySlugLevel2: ':organization/:brand',
  orgHierarchySlugLevel3: ':organization/:brand/:location',
  candidatePreview: 'candidate-preview',
  success: 'success',
  signin: 'signin',
  registration: 'register',
  templateManagement: 'template-management',
  invite: 'invite',
  couponsManagement: 'coupons-management',
};

export const KEYS = {
  userInfo: 'userInfo',
  adminInfo: 'adminInfo',
  authToken: 'authToken',
  jobPositionList: 'jobPositionList',
  skillsId: 'skillId',
  newSkills: 'newSkills',
};
interface IconMap {
  [key: string]: JSX.Element;
}
export interface IImportMap {
  [key: string]: string;
}

export const DEFAULTVALUES = {
  dateShortFormat: 'MM/DD/YYYY',
};

export const MENUICONS = (type: string): JSX.Element => {
  const icons: IconMap = {
    Cvdashboard: Cvdashboard(),
    Cvjobs: Cvjobs(),
    Cvuser: Cvuser(),
    Cvquestion: Cvquestion(),
    Cvcompany: Cvcompany(),
    Cvbag: Cvjobpositions(),
    Cvteam: Cvteam(),
    Cvreports: Cvreports(),
    Cvsettings: Cvsettings(),
    Cvsubscriptions: Cvsubscription(),
    Cvorganizations: Cvorganization(),
    Cvscheduleddemo: CvscheduleDemo(),
    Cvcmspages: Cvcmspages(),
    Cvorganizationhierarchycontrol: Cvhierarchy(),
    Cvbrand: Cvbrand(),
    Cvtemplate: CvTemplate(),
    CvCouponManagement: CvCoupon(),
  };
  return icons[type];
};
export enum CandidateStatusTitleEnum {
  TO_BE_INTERVIEWED = 'Interview Not Yet Completed',
  PENDING_INVITATION = 'Invitation Created',
  INVITATION_SENT = 'Invitation Sent',
  ELIGIBILITY_FAILED = 'Requirements Not Met',
  INTERVIEWED = 'New/To Be Reviewed',
  HIRED = 'Hired',
  REJECTED = 'Not A Match',
  REVIEWED = 'Reviewed',
}
export const STATUSTAG = (type: string): JSX.Element => {
  const tags: IconMap = {
    INPROGRESS: statusTag(
      CandidateStatusTitleEnum.TO_BE_INTERVIEWED,
      'tag-to-be-interviewed'
    ),
    PENDING_INVITATION: statusTag(
      CandidateStatusTitleEnum.PENDING_INVITATION,
      'tag-pending-invitation'
    ),
    SEND_INVITATION: statusTag(
      CandidateStatusTitleEnum.INVITATION_SENT,
      'tag-send-invitation'
    ),
    ELIGIBILITY_FAILED: statusTag(
      CandidateStatusTitleEnum.ELIGIBILITY_FAILED,
      'tag-eligibility-failed'
    ),
    COMPLETED: statusTag(
      CandidateStatusTitleEnum.INTERVIEWED,
      'tag-interviewed'
    ),
    REVIEWED: statusTag(CandidateStatusTitleEnum.REVIEWED, 'tag-reviewed'),
    HIRED: statusTag(CandidateStatusTitleEnum.HIRED, 'tag-hired'),
    REJECTED: statusTag(CandidateStatusTitleEnum.REJECTED, 'tag-rejected'),
    ACTIVE: statusTag('Active', 'tag-active'),
    INACTIVE: statusTag('Inactive', 'tag-inactive'),
    DRAFT: statusTag('Draft', 'tag-platinum'),
  };
  return tags[type];
};

export enum answerType {
  CHECKBOX = 'CHECKBOX',
  DROPDOWN = 'DROPDOWN',
  RADIO = 'RADIO',
  FILEUPLOAD = 'FILEUPLOAD',
  VIDEO = 'VIDEO',
  TEXT = 'TEXT',
}

export const candidateDetailFileUpload = {
  allowTypesForResume: [
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  ],
  allowTypesForOther: [
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'image/jpeg',
    'image/png',
    'image/jpg',
  ],
  allowTypeForResumeMSG: '.pdf, .docx, .doc',
  allowTypeForOtherMSG: '.pdf, .docx, .doc, .png, .jpg, .jpeg',
  maxFileSize: 5,
};

export enum CandidateAnswerPageType {
  MAIN = 'MAIN',
  SAMPLE = 'SAMPLE',
}
export enum localStorageKeys {
  candidateUUIDForAnswer = 'CANDIDATEUUIDANSWER',
  organizationLogo = 'ORGANIZATIONLOGO',
  lastAttempQuestionId = 'LASTATTEMPTQUESTIONID',
  lastAttemptQuestionOffset = 'LASTATTEMPTQUESTIONOFFSET',
  siteLogo = 'SITELOGO',
  recruiterSlug = 'RECRUITERSLUG',
  candidateSharedUuid = 'CANDIDARESHAREDUUID',
  registerUserDetails = 'REGISTERUSERDETAILS',
}

export enum candidateDetailsKey {
  EMAIL = 'EMAIL',
  RESUME = 'RESUME',
}

export const readMoreCharacterCount = 250;
export const questionReadMoreCharacterCount = 130;
export const defaultStateCount = 60; // For United States State count
export const defaultLimit = -1;
export const perPageLimit = 15;
export const defaultOffSet = 1;
export const ratingLimit = 5;
export const initialOffset = 1;
export const initialLimit = 25;
export const searchString = 2;
export const pagePerSizeLimit = [25, 50, 75, 100];

export enum VideoTypeEnum {
  UPLOAD = 'UPLOAD',
  RECORD = 'RECORD',
  LINK = 'LINK',
}

export enum UserTypeEnum {
  USER = 'USER',
  ADMIN = 'ADMIN',
}
export enum DiscountType {
  FIXED = 'FLAT',
  PERCENTAGE = 'PERCENTAGE',
}

export enum Duration {
  FOREVER = 'FOREVER',
  ONCE = 'ONCE',
  REPEATING = 'REPEATING',
}

export enum Status {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}
export const maxImageSize = 2000000;
export const maxVideoSize = 100000000;
export const maxImportSize = 1024000000;

export const imageExtension = {
  'image/png': ['.png'],
  'image/jpg': ['.jpg'],
  'image/jpeg': ['.jpeg'],
};

export const videoExtension = {
  'video/mp4': ['.mp4'],
  'video/mov': ['.mov'],
};

export const importExtension = {
  'application/vnd.ms-excel': ['.xls', '.xlsx'],
  'text/csv': ['.csv'],
};

export const imageSizeMB = 2;
export const videoSizeMB = 100;
export const importSizeMB = 1024;

export const AcceptedVideoExtensions = `.MP4, .MOV. Maximum file size: ${videoSizeMB} MB`;
export const AcceptedImageExtensions = `.JPG, .PNG, .JPEG. Maximum file size: ${imageSizeMB} MB`;
export const AcceptedImportExtension = `.csv, .xlsx, .xls. Maximum file size:${importSizeMB} MB`;

export const STATUSMESSAGE = {
  INACTIVE_STATUS_LABEL:
    'Are you sure you want to change status Inactive to Active?',
  ACTIVE_STATUS_LABEL:
    'Are you sure you want to change status Active to Inactive?',
  INTERVIEWASTATUSCHANGE:
    'Please note that once you change the status of a candidate, it cannot be reverted. Please carefully consider your decision before proceeding with the status change.',
  COMPANYWARNINGMESSAGE:
    'Changing the status of the franchise/location to inactive will impact associated jobs, candidates, and team members. Please review the implications before proceeding.',
  BRANDWARNINGMESSAGE:
    'Changing the status of the brand to inactive will impact associated franchises/locations, jobs, candidates, and team members. Please review the implications before proceeding.',
  TEAMMEMBERWARNINGMESSAGE:
    'Before inactivating the team member, Please double-check before proceeding with the change to avoid any unintended consequences.',
  ORGANIZATIONWARNINGMESSAGE:
    'Before inactivating the organization, please ensure that the associated jobs, brands and franchises/locations are first set to inactive. Inactivating the organization will have implications on associated brands, franchises/locations, jobs, candidates, and team members.',
  RESETPAYMENTSTATUS: 'Are you sure you want to remove payment restriction?',
};
export const GETSTATUSMESSAGE = (type: string): JSX.Element => {
  const tags: IconMap = {
    BrandStatus: statusMessage(STATUSMESSAGE.BRANDWARNINGMESSAGE),
    CompanyStatus: statusMessage(STATUSMESSAGE.COMPANYWARNINGMESSAGE),
    TeamMemberStatus: statusMessage(STATUSMESSAGE.TEAMMEMBERWARNINGMESSAGE),
    OrganizationStatus: statusMessage(STATUSMESSAGE.ORGANIZATIONWARNINGMESSAGE),
    ResetPaymentStatus: statusMessage(STATUSMESSAGE.RESETPAYMENTSTATUS),
  };
  return tags[type];
};
export enum StatusEnum {
  DRAFT = 'DRAFT',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  INPROGRESS = 'INPROGRESS',
  PENDING_INVITATION = 'PENDING_INVITATION',
  SEND_INVITATION = 'SEND_INVITATION',
  ELIGIBILITY_FAILED = 'ELIGIBILITY_FAILED',
  COMPLETED = 'COMPLETED',
  HIRED = 'HIRED',
  REJECTED = 'REJECTED',
  REVIEWED = 'REVIEWED',
}
export const CandidateStatusList = [
  { title: CandidateStatusTitleEnum.HIRED, name: StatusEnum.HIRED },
  {
    title: CandidateStatusTitleEnum.TO_BE_INTERVIEWED,
    name: StatusEnum.INPROGRESS,
  },
  {
    title: CandidateStatusTitleEnum.PENDING_INVITATION,
    name: StatusEnum.PENDING_INVITATION,
  },
  {
    title: CandidateStatusTitleEnum.INVITATION_SENT,
    name: StatusEnum.SEND_INVITATION,
  },
  { title: CandidateStatusTitleEnum.INTERVIEWED, name: StatusEnum.COMPLETED },
  { title: CandidateStatusTitleEnum.REJECTED, name: StatusEnum.REJECTED },
  {
    title: CandidateStatusTitleEnum.ELIGIBILITY_FAILED,
    name: StatusEnum.ELIGIBILITY_FAILED,
  },
  {
    title: CandidateStatusTitleEnum.REVIEWED,
    name: StatusEnum.REVIEWED,
  },
];
declare const window: MyWindow;
export const OT = window.OT;
export const MaximumVideoRecordingMinutes = 300; // in Seconds
export const OrganizationMasterUUID = 'master_data';
export const OrganizationMaster = [
  {
    ['name']: 'Master Data',
    ['company_uuid']: 'master_data',
    ['id']: '0',
    ['child_company']: [],
  },
];
export enum ImportTypeEnum {
  CANDIDATE = 'candidateImport',
  QUESTION = 'questionImport',
  JOB = 'jobImport',
}
export const GETIMPORTDOWNLOADFILE = (type: string): string => {
  const tags: IImportMap = {
    [ImportTypeEnum.CANDIDATE]: `${process.env.REACT_APP_IMPORT_URL}/sample_candidate_import.xlsx`,
    [ImportTypeEnum.QUESTION]: `${process.env.REACT_APP_IMPORT_URL}/sample_question_import.xlsx`,
    [ImportTypeEnum.JOB]: `${process.env.REACT_APP_IMPORT_URL}/sample_job_import.xlsx`,
  };
  return tags[type];
};

export enum FilterEnum {
  Group = 'Group',
  Job = 'Job',
  Company = 'Company',
  JobTitle = 'JobTitle',
  Status = 'Status',
  Type = 'Type',
  Organization = 'Organization',
  Category = 'Category',
  Brand = 'Brand',
}

export enum SalaryTypeEnum {
  HOURLY = 'HOURLY',
  DEFAULT = 'default',
  hourly = '(Hourly)',
  ANNUALLY = 'ANNUALLY',
  annually = '(Annually)',
}

export enum ReducerName {
  ORGANIZATION = 'Organization',
  JOBTITLE = 'JobTitle',
  COMPANY = 'Company',
  GROUP = 'Group',
  CATEGORY = 'Category',
  JOB = 'Job',
  BRAND = 'Brand',
}
export const editorFirstEnterGenerated = '<div><br></div>';

export const dueDateInfo =
  'If the job is not a permanent position and has a specific timeframe for hiring the candidate, kindly indicate the desired due date for closing the hiring process.';

export enum DashboardRedirectModuleName {
  JOBPOSITION = 'JOBSPOSITION',
  APPLICATION = 'APPLICATION',
  CANDIDATEINVITES = 'CANDIDATEINVITES',
  CANDIDATECOMPLETEDINTERVIEW = 'CANDIDATECOMPLETEDINTERVIEW',
  BAR_CHART_EXPORT = 'BAR_CHART_EXPORT',
  PIE_CHART_EXPORT = 'PIE_CHART_EXPORT',
}

export enum UserQuestionTypeEnum {
  CUSTOM = 'CUSTOM',
  GLOBAL = 'GLOBAL',
  Organisation = 'Custom',
  Master = 'Standard',
  ALL = 'All',
}

export enum jobQuestionTypeEnum {
  RESUME = 'RESUME',
}

export enum RatingEnum {
  YES = 'YES',
  NO = 'NO',
  MAYBE = 'MAYBE',
}

export enum CompanyTypeEnum {
  ORGANIZATION = 'ORGANIZATION',
  BRAND = 'BRAND',
  LOCATION = 'LOCATION',
}

export enum CustomDateRangeEnum {
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
  YEARLY = 'YEARLY',
  CUSTOM = 'CUSTOM',
}

export enum PaymentStatusEnum {
  CANCELLED = 'CANCELLED',
  PENDING = 'PENDING',
}

export enum StaticCMSSlug {
  TERM_CONDITIONS = 'terms-and-conditions',
}

export const TWOFIFTYPLUSREGISTER = [
  { title: '250+ Employees', uuid: '250Plus' },
];

export const socialProvider = {
  GOOGLE: 'gmail',
  FACEBOOK: 'facebook'
}

export const ToolTipMessages = {
  orgMgmAboutUs: 'Should provide a concise yet comprehensive overview of the Organization',
  orgMgmOrgVideo: 'Video that communicates your Organizations identity, culture, etc.',
  orgMgmOrgLogo: 'Image that will be displayed on Job Posting page',
  orgMgmLoginImage: 'Image that will be displayed on Account Login page',
  orgMgmResetImage: 'Image that will be displayed on Reset Password page',
  jobPostingNoOpening: 'Only used if you want this position to close once number of openings is filled.',
  jobPostingSkills: 'Key competencies that the employer is seeking in candidates.',
  jobPostingSaveButton: 'Click on Save and Start Create Customized Videos and Text Questions',
  questionInterview: 'Video interview questions to applicant.',
  questionKnockout: 'Question that quality of eliminate applicants before entering the video interview.',
  questionApplicantDetails: 'Data field for applicants information (first and last name, email address and resume are default fields).',
  quesSaveButton: 'Click on Save Button. if you want to create another question. repeat process then move to step (XX)',
  jobStep1ByPass: 'Removes job posting detail and send applicant directly to applicant details',
  jobStep1Tagline: 'Phrase or statement that summarizes the essence of a particular job. 200 characters max.',
  jobStep1IntroVideo: 'This is the first video the applicant watches before the video interview. It should let the applicant know what to expect before starting the interview.',
  jobStep1OutroVideo: 'This is the last video the applicant watches after completing the video interview process. It should let the applicant know their time was appreciated and any next steps the applicant should be aware of.',
  jobStep3ApplicantDetails: 'Select from Custom questions previously created in Question Bank or utilize our default First Name, Last Name, Email Address and Resume.',
  jobStep3KnockoutQuestion: 'Select from Custom questions previously created in Question Bank. There is no Standard (default) option',
  jobStep3InterviewQuestion: 'Select from Custom questions previously created in Question Bank or utilize our Standard (default).',
  jobStep3AutoRecordingGap: 'Amount of time applicant has to think after question is asked',
  jobStep3AnswerDuration: 'Amount of time to answer question',
  jobStep3NoRetake: 'Times applicant can retake their answer for each question',
  jobStep3Gap: 'Amount of time between each question',
  jobStep3ResumeField: 'Upload Resume can be removed by deselecting is Visible or make required by selecting is Required. Default status is visible and optional.',
  jobStep3PreviewInterviewButton: 'Allows you to review the entire application to video interview process prior to publishing',
  jobStep3SaveButton: 'Save your build progress without publishing',
  jobStep3SavePublishButton: 'Completes the build process and generates a job posting URL that directs apllicants to job posting page.',
}

export const GENERAL_JOB_POSITIONS = {
  title: 'General Job Position',
  uuid: 'GENERAL_JOB'
}