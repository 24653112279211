import { gql } from '@apollo/client';

export interface Meta {
  message: string;
  message_code: string;
  status_code: number;
  status: boolean;
}

export const META_FRAGMENT = gql`
  fragment MetaFragment on Meta {
    status
    message
    message_code
    status_code
  }
`;
