import React from 'react';

const CvBrand = (): JSX.Element => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
    >
      <defs>
        <clipPath id='clip-Brand'>
          <rect width='24' height='24' />
        </clipPath>
      </defs>
      <g id='Brand' clipPath='url(#clip-Brand)'>
        <path
          id='Path_13222'
          data-name='Path 13222'
          d='M3014.87,5923.46,3014,5930l4.59-2.75a1.3,1.3,0,0,1,.3-.16.831.831,0,0,1,.22,0,1.3,1.3,0,0,1,.3.16L3024,5930l-.87-6.54m.3-11.21a1.5,1.5,0,0,0,.82.82l1.31.55a1.522,1.522,0,0,1,.82,1.99l-.54,1.31a1.55,1.55,0,0,0,0,1.16l.54,1.31a1.527,1.527,0,0,1-.82,1.99l-1.31.55a1.548,1.548,0,0,0-.83.82l-.54,1.31a1.522,1.522,0,0,1-1.99.82l-1.31-.54a1.553,1.553,0,0,0-1.16,0l-1.31.54a1.522,1.522,0,0,1-1.99-.82l-.55-1.31a1.5,1.5,0,0,0-.82-.82l-1.31-.55a1.522,1.522,0,0,1-.82-1.99l.54-1.31a1.55,1.55,0,0,0,0-1.16l-.54-1.31a1.515,1.515,0,0,1-.12-.59,1.532,1.532,0,0,1,.45-1.07,1.323,1.323,0,0,1,.49-.33l1.31-.55a1.5,1.5,0,0,0,.82-.82l.55-1.31a1.522,1.522,0,0,1,1.99-.82l1.31.54a1.553,1.553,0,0,0,1.16,0l1.31-.54a1.522,1.522,0,0,1,1.99.82l.55,1.31Z'
          transform='translate(-3007 -5908)'
          fill='none'
          stroke='#9c9baa'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='2'
        />
      </g>
    </svg>
  );
};
export default CvBrand;
