import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloLink,
  from,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';
import { ROUTES, localStorageKeys } from 'src/config/constant';
import { getAuthToken, logout, adminLogout, getAdminAuthToken, getRecruiterSiteSlugBased, redirect404PageInvalidURL } from 'src/config/helpers';
import { toast } from 'react-toastify';
import { checkIfAdminFromSlug } from '@utils/helpers';

const httpLink = new HttpLink({ uri: process.env.REACT_APP_API_GATEWAY_URL });

const xOpentokAuth = localStorage.getItem('openTock-Token');
let recruiterSlug = localStorage.getItem(localStorageKeys.recruiterSlug);

const authMiddleware = new ApolloLink((operation, forward) => {
  const currSlug = window.location.pathname;
  let slugArray = [];
  slugArray = currSlug.split('/');
  const found = slugArray.find((element) => ['admin'].includes(element));
  const candidatePreview = slugArray.find((ele) => [ROUTES.candidatePreview].includes(ele));


  // Check Recruiter Slug for login, forgot, reset password there then pass latest slug value
  const isRequireUpdatedRcSlug = slugArray.find((element) => [ROUTES.login].includes(element.toLocaleLowerCase()) || [
    ROUTES.forgotPassword
  ].includes(element.toLocaleLowerCase()) || [ROUTES.resetPassword].includes(element.toLocaleLowerCase()));
  // Check Recruiter Slug is there and local storage doesn't contain it's value 
  if (recruiterSlug === null || recruiterSlug === undefined || recruiterSlug === 'undefined' || isRequireUpdatedRcSlug) {
    recruiterSlug = getRecruiterSiteSlugBased();
  }

  let token;
  if (found) {
    token = getAdminAuthToken();
  } else if (candidatePreview) {
    token = getAdminAuthToken() || getAuthToken() || null;
  } else {
    token = getAuthToken();
  }
  const customHeaders = {
    Authorization: `Bearer ${token}` || null,
    'x-opentok-auth': xOpentokAuth,
    'Content-Type': 'application/json',
    'slug': recruiterSlug
  }
  if (found) {
    delete customHeaders?.slug;
  }

  // add the authorization to the headers
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...customHeaders,
      ...headers,
    },
  }));
  return forward(operation);
  // Modify data
  // return forward(operation).map((response) => {
  //   return response.data
  // })
});
const getUnauthenticatedError = () => {
  if (getAuthToken()) {
    logout();
    window.location.replace(`/${recruiterSlug}/${ROUTES.login}`);
  } else if (getAdminAuthToken()) {
    adminLogout();
    window.location.replace(`/${ROUTES.admin}/${ROUTES.login}`);
  } else {
    if (checkIfAdminFromSlug()) {
      window.location.replace(`/${ROUTES.admin}/${ROUTES.login}`);
    } else {
      if (recruiterSlug) {
        window.location.replace(`/${recruiterSlug}/${ROUTES.login}`);
      }
    }
  }
}

const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, path, extensions }) => {
      if (path === 'recruiterLogout') {
        logout();
      } else if (path === 'adminLogout') {
        adminLogout();
      } else if (message === 'Unauthenticated.') {
        getUnauthenticatedError();
      } else if (extensions.category === 'validation') {
        for (const key in extensions?.validation) {
          key &&
            toast.error(extensions?.validation[key][0]);
        }
      } else if (extensions?.meta?.message_code === 'UNAUTHORIZED_ACCESS') {
        toast.error(extensions?.meta?.message, {
          onClose: () => window.location.replace(ROUTES.dashboard),
        });
      } else if (extensions?.meta?.message_code === 'PENDING_SUBSCRIPTION'
       || extensions?.meta?.message_code === 'PLAN_EXPIRED') {
         toast.error(extensions?.meta?.message)
        redirect404PageInvalidURL()
        return true;
      } else if (extensions?.meta?.message_code === 'INVALID_URL') {
        toast.error(extensions?.meta?.message);
        redirect404PageInvalidURL();
      } else if (extensions?.meta?.message_code === 'NO_RECORD_FOUND') {
        const splitPath = window.location.pathname.split('/');
        // Candidate details public page redirection handle
        if (splitPath?.[1] === ROUTES.candidateDetails) {
          let redirectionURL = '';
          if (getAuthToken()) {
            redirectionURL = `/${ROUTES.app}/${ROUTES.candidate}`
          } else if (getAdminAuthToken()) {
            redirectionURL = `/${ROUTES.admin}/${ROUTES.candidate}`
          } else {
            toast.error(extensions?.meta?.message, {
              onClose: () => redirect404PageInvalidURL(),
            });
            return true;
          }
          toast.error(extensions?.meta?.message, {
            onClose: () => window.location.replace(`${redirectionURL}`),
          });
        } else {
          toast.error(extensions?.meta?.message, {
            onClose: () => window.location.replace(`/${splitPath?.[1]}/${splitPath?.[2]}`),
          });
        }
      }
      else {
        toast.error(message);
      }
    });
  }
});

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([authMiddleware, errorLink, httpLink]),
});

const uploadLink = createUploadLink({
  uri: process.env.REACT_APP_API_GATEWAY_URL,
});

export const uploadFileClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([authMiddleware, errorLink, uploadLink]),
});
