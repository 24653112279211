import React from 'react';

const CvQuestion = (): JSX.Element => {
  return (
    <>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='24'
        height='24'
        viewBox='0 0 24 24'
      >
        <defs>
          <clipPath id='clip-Question_Bank'>
            <rect width='24' height='24' />
          </clipPath>
        </defs>
        <g
          id='Question_Bank'
          data-name='Question Bank'
          clipPath='url(#clip-Question_Bank)'
        >
          <path
            id='Path_12830'
            data-name='Path 12830'
            d='M2887.875,1435.126h.011m4.865,0h.011m4.865,0h.011m-10.3,8.126v2.535c0,.574,0,.856.12,1.008a.533.533,0,0,0,.422.206c.195,0,.412-.184.867-.542l2.589-2.069a6.6,6.6,0,0,1,1.084-.78,3.526,3.526,0,0,1,.823-.292,7,7,0,0,1,1.333-.065h2.73c1.82,0,2.73,0,3.424-.358a3.107,3.107,0,0,0,1.419-1.419c.358-.693.358-1.6.358-3.424v-5.85c0-1.82,0-2.73-.358-3.424a3.106,3.106,0,0,0-1.419-1.419c-.694-.358-1.6-.358-3.424-.358h-9.1c-1.82,0-2.73,0-3.424.358a3.1,3.1,0,0,0-1.419,1.419c-.358.693-.358,1.6-.358,3.424v6.717a8.4,8.4,0,0,0,.108,1.929,3.275,3.275,0,0,0,2.3,2.3A8.4,8.4,0,0,0,2887.333,1443.251Zm1.083-8.126a.542.542,0,1,1-.542-.542A.537.537,0,0,1,2888.417,1435.126Zm4.875,0a.542.542,0,1,1-.542-.542A.536.536,0,0,1,2893.292,1435.126Zm4.875,0a.542.542,0,1,1-.542-.542A.536.536,0,0,1,2898.168,1435.126Z'
            transform='translate(-2880.5 -1424.5)'
            fill='none'
            stroke='#9c9baa'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='2'
          />
        </g>
      </svg>
    </>
  );
};
export default CvQuestion;
