import React from 'react';

const CvScheduleDemo = (): JSX.Element => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="22"
            viewBox="0 0 20 22"
        >
            <path
                id="Path_14997"
                data-name="Path 14997"
                d="M2300,5347.5v-2.7a7.371,7.371,0,0,0-.33-3.16,2.865,2.865,0,0,0-1.31-1.31,7.375,7.375,0,0,0-3.16-.33h-8.4a7.375,7.375,0,0,0-3.16.33,2.865,2.865,0,0,0-1.31,1.31,7.371,7.371,0,0,0-.33,3.16v8.4a7.371,7.371,0,0,0,.33,3.16,2.865,2.865,0,0,0,1.31,1.31,7.375,7.375,0,0,0,3.16.33h4.7m8.5-12h-18m13-8v4m-8-4v4m10,15v-6m-3,3h6"
                transform="translate(-2281 -5337)"
                fill="none"
                stroke="#9c9baa"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
            />
        </svg>
    );
};
export default CvScheduleDemo;
