import React from 'react';

const CvHierarchy = (): JSX.Element => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <defs>
                    <clipPath id="clip-Hierarchy">
                        <rect width="24" height="24" />
                    </clipPath>
                </defs>
                <g id="Hierarchy" clipPath="url(#clip-Hierarchy)">
                    <path id="Path_13328" data-name="Path 13328" d="M752,4084h-.2a7.389,7.389,0,0,1-3.162-.33,2.936,2.936,0,0,1-1.311-1.31,7.439,7.439,0,0,1-.327-3.16v-6.4a7.439,7.439,0,0,1,.327-3.16,2.936,2.936,0,0,1,1.311-1.31,7.389,7.389,0,0,1,3.162-.33h.2m0,16a2,2,0,1,0,2-2A2,2,0,0,0,752,4084Zm0-16a2,2,0,1,0,2-2A2,2,0,0,0,752,4068Zm-10,8h10m-10,0a2,2,0,1,1-2-2A2,2,0,0,1,742,4076Zm10,0a2,2,0,1,0,2-2A2,2,0,0,0,752,4076Z" transform="translate(4088 -735) rotate(90)" fill="none" stroke="#9c9baa" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                </g>
            </svg>

        </>
    );
};
export default CvHierarchy;
