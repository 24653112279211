import React from 'react';

const CvTeam = (): JSX.Element => {
  return (
    <>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='24'
        height='24'
        viewBox='0 0 24 24'
      >
        <defs>
          <clipPath id='clip-Team'>
            <rect width='24' height='24' />
          </clipPath>
        </defs>
        <g id='Team' clipPath='url(#clip-Team)'>
          <path
            id='Path_12832'
            data-name='Path 12832'
            d='M1904,1727v-2.222a4.427,4.427,0,0,0-3.3-4.3m-3.85-13.156a4.465,4.465,0,0,1,0,8.244M1898.5,1727a11.346,11.346,0,0,0-.33-3.922,4.473,4.473,0,0,0-2.387-2.411,11.011,11.011,0,0,0-3.883-.333h-3.3a11.011,11.011,0,0,0-3.883.333,4.473,4.473,0,0,0-2.387,2.411A11.342,11.342,0,0,0,1882,1727m12.65-15.556a4.4,4.4,0,1,1-4.4-4.444A4.421,4.421,0,0,1,1894.65,1711.445Z'
            transform='translate(-1880.5 -1704.5)'
            fill='none'
            stroke='#9c9baa'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='2'
          />
        </g>
      </svg>
    </>
  );
};
export default CvTeam;
