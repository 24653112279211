import React from 'react';

const CvSubscription = (): JSX.Element => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="23.629"
            height="19"
            viewBox="0 0 23.629 19"
        >
            <g
                id="Group_13038"
                data-name="Group 13038"
                transform="translate(23904.814 6989.5)"
            >
                <path
                    id="Path_16764"
                    data-name="Path 16764"
                    d="M477,4781h-2.5a1.5,1.5,0,0,0,0,3h1a1.5,1.5,0,0,1,0,3H473m2-7v1m0,6v1"
                    transform="translate(-24368 -11764)"
                    fill="none"
                    stroke="#9c9baa"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                />
                <path
                    id="Path_16763"
                    data-name="Path 16763"
                    d="M2568,826.875a8.5,8.5,0,0,0-5-15.375h-.5m.5,17a8.5,8.5,0,0,1-5-15.375m4,17.275,2-2-2-2m2-12.8-2-2,2-2" transform="translate(-23073 -9543) rotate(90)"
                    fill="none"
                    stroke="#9c9baa"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                />
            </g>
        </svg>
    );
};
export default CvSubscription;
