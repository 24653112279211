import { createSlice } from '@reduxjs/toolkit';
export interface Istatus {
    Status?: string[];
    IsTrash?: string[];
    [key: string]: any // Needed to Defne any here because accesssing value of redux in checkbox component like this ( const filterDataFromRedux = useSelector((state: RootState) => state?.checkboxFilterDataSlice?.[dynamicName] );)
}

const initialState: Istatus = {
    Status: [],
    IsTrash: []
};

export const statusFilterSlice = createSlice({
    name: 'statusFilter',
    initialState,
    reducers: {
        Status: (state, action) => {
            state.Status = action.payload.Status
        },
        IsTrash: (state, action) => {
            state.IsTrash = action.payload.IsTrash
        },
        clearAllStatus: (state) => {
            state.Status = initialState.Status
            state.IsTrash = initialState.IsTrash
        },
    },
});

// Action creators are generated for each case reducer function
export const {
    Status,
    IsTrash,
    clearAllStatus,
} = statusFilterSlice.actions;

export default statusFilterSlice.reducer;
