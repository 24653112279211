import React from 'react';
import { toast } from 'react-toastify';
import { ROUTES, KEYS, localStorageKeys } from './constant';
import { RoleEnum } from '@framework/graphql/mutations/team';

export const setCurrentUser = (data) => {
  return localStorage.setItem(KEYS.userInfo, JSON.stringify(data));
};

export const setCurrentAdmin = (data) => {
  return localStorage.setItem(KEYS.adminInfo, JSON.stringify(data));
};

export const getCurrentUser = () => {
  return localStorage.getItem(KEYS.userInfo)
    ? JSON.parse(localStorage.getItem(KEYS.userInfo))
    : null;
};

export const getCurrentAdmin = () => {
  return localStorage.getItem(KEYS.adminInfo)
    ? JSON.parse(localStorage.getItem(KEYS.adminInfo))
    : null;
};

export const getAuthToken = () => {
  const token = getCurrentUser()
    ? getCurrentUser()?.recruiterLogin?.data?.access_token
    : null;
  return token;
};

export const getUserCompanyType = () => {
  const token = getCurrentUser()
    ? getCurrentUser()?.recruiterLogin?.data?.user?.company_type
    : null;
  return token;
};

export const getAdminAuthToken = () => {
  const token = getCurrentAdmin()
    ? getCurrentAdmin()?.adminLogin?.data?.access_token
    : null;
  return token;
};

export const verifyAuth = () => {
  return localStorage.getItem(KEYS.userInfo) ? true : false;
};

export const verifyAdminAuth = () => {
  return localStorage.getItem(KEYS.adminInfo) ? true : false;
};

export const getUserInfo = () => {
  return getCurrentUser() ? getCurrentUser()?.recruiterLogin?.data?.user : null;
};

export const getAdminInfo = () => {
  return getCurrentAdmin() ? getCurrentAdmin()?.adminLogin?.data?.user : null;
};

export const logout = () => {
  localStorage.removeItem(KEYS.userInfo);
  localStorage.removeItem(localStorageKeys.siteLogo);
  const recruiterLoginURL = localStorage.getItem(localStorageKeys.recruiterSlug);
  window.location.replace(`/${recruiterLoginURL}/${ROUTES.login}`);
  localStorage.removeItem(localStorageKeys.recruiterSlug);
  localStorage.removeItem(localStorageKeys.company_uuid);
};

export const adminLogout = () => {
  localStorage.removeItem(KEYS.adminInfo);
  localStorage.removeItem(localStorageKeys.siteLogo);
  localStorage.removeItem(localStorageKeys.company_uuid);
  window.location.replace(`/${ROUTES.admin}/${ROUTES.login}`);
};

export const redirect404PageInvalidURL = () => {
  setTimeout(() => {
    localStorage.removeItem(KEYS.userInfo);
    localStorage.removeItem(KEYS.adminInfo);
    localStorage.removeItem(localStorageKeys.siteLogo);
    localStorage.removeItem(localStorageKeys.recruiterSlug);
    window.location.replace(`/${ROUTES.page500}`);
  }, 1500);
};

export const errorHandler = (error) => {
  toast.error(error.message);
  // Display multiple validation message
  // if (error?.statusCode === MESSAGE_CODE.BAD_REQUEST)
};

export const requiredSymbol = () => {
  return <span className='text-danger'>*</span>;
};


export const currencyFormatter = (value) => {
  const USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  return USDollar.format(value)
}
export const shortNameGenerater = (value) => {
  const nameParts = value.split(' ')
  return (nameParts[0].charAt(0) + (nameParts[1] ? nameParts[1].charAt(0) : '')).toUpperCase()
}

export const scrollToError = () => {
  const errors = document.getElementsByClassName('Mui-error')
  if (errors?.length) {
    errors[0].scrollIntoView({ block: 'center', behavior: 'smooth' });
    return false
  }
}

export const dataURLtoFile = (dataurl) => {
  const filename = `recording_thumbnail_${new Date().getTime()}.png`;
  const arr = dataurl.split(',');
  const mime = arr[0].split(':')[1].split(';')[0];
  const bstr = window.atob(arr[arr.length - 1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}
// getFileName function use for get the file name of uploaded in candidate details.
export const getFileName = (URL) => {
  const filename = URL.substring(URL.lastIndexOf('/') + 1);
  return filename;
};

export const checkIsAdmin = () => {
  return getAdminInfo()?.role_id === RoleEnum.MEGA_ADMIN;
}

/**
 * Get Recruiter Slug from the current URL if URL is contains login, forgot-password or reset password
 * @returns string
 */
export const getRecruiterSiteSlugBased = () => {
  const currentSlug = window.location.pathname;
  let slugValueArray = [];
  slugValueArray = currentSlug.split('/');
  // Check Recruiter Slug is there and local storage doesn't contain it's value 
  const isRecruiterSlug = slugValueArray.find((element) => [ROUTES.login].includes(element.toLocaleLowerCase()) || [
    ROUTES.forgotPassword
  ].includes(element.toLocaleLowerCase()) || [ROUTES.resetPassword].includes(element.toLocaleLowerCase()));
  if (isRecruiterSlug) {
    if (slugValueArray.length > 0) {
      const loginIndex = slugValueArray.findIndex((ele) => ele.toLocaleLowerCase() === ROUTES.login || ele
        .toLocaleLowerCase() === ROUTES.forgotPassword || ele.toLocaleLowerCase() === ROUTES.resetPassword);
      let rslug = '';
      if (loginIndex !== -1) {
        slugValueArray.forEach((sa, index) => {
          if (index !== 0 && index < loginIndex) {
            if (!rslug) {
              rslug = `${sa}`;
            } else {
              if (sa !== '/' && sa !== '') {
                rslug = `${rslug}/${sa}`;
              }
            }
          }
        });
      }
      return rslug;
    }
  }
}

export const convertThirdLevelParentChildOrganization = (data) => {
  const map = {};
  const result = [];

  data?.forEach(item => {
    const { name, brand } = item;
    const companyUuid = item.company_uuid;

    if (!map[companyUuid]) {
      map[companyUuid] = { companyUuid, name, children: [] };
      result.push(map[companyUuid]);
    }

    if (brand) {
      const { company_uuid: brandUuid, name: brandName, location } = brand;

      if (!map[brandUuid]) {
        map[brandUuid] = { companyUuid: brandUuid, name: brandName, children: [] };
        map[companyUuid].children.push(map[brandUuid]);
      }

      if (location) {
        const { company_uuid: locationUuid, name: locationName } = location;

        if (!map[locationUuid]) {
          map[locationUuid] = { companyUuid: locationUuid, name: locationName, children: [] };
          map[brandUuid].children.push(map[locationUuid]);
        }
      }
    }
  });
  const organizationRecords = [];
  result?.forEach((parent) => {
    const teamOrganization = {};
    teamOrganization['company_uuid'] = parent.companyUuid;
    teamOrganization['name'] = parent.name;
    teamOrganization['parentUuid'] = '';
    teamOrganization['type'] = 'parent';
    organizationRecords.push(teamOrganization);
    parent.children.forEach((child) => {
      const teamOrganization = {};
      teamOrganization['company_uuid'] = child.companyUuid;
      teamOrganization['name'] = child.name;
      teamOrganization['parentUuid'] = parent.companyUuid;
      teamOrganization['type'] = 'child';
      organizationRecords.push(teamOrganization);
      child?.children?.forEach((grandChild) => {
        const teamOrganization = {};
        teamOrganization['company_uuid'] = grandChild.companyUuid;
        teamOrganization['name'] = grandChild.name;
        teamOrganization['parentUuid'] = child.companyUuid;
        teamOrganization['grandUuid'] = parent.companyUuid;
        teamOrganization['type'] = 'grandChild';
        organizationRecords.push(teamOrganization);
      });
    });
  });
  return organizationRecords;
};

export const abbreviateNumber = (number) => {
  const COUNT_FORMATS =
    [
      {
        letter: '',
        limit: 1e3
      },
      {
        letter: 'K',
        limit: 1e6
      },
      {
        letter: 'M',
        limit: 1e9
      },
      {
        letter: 'B',
        limit: 1e12
      },
      {
        letter: 'T',
        limit: 1e15
      }
    ];
  const format = COUNT_FORMATS.find(format => (number < format.limit));

  number = (1000 * number / format.limit);
  number = Math.round(number * 10) / 10;

  return (number + format.letter);
}
