import { createTheme, outlinedInputClasses } from '@mui/material';

declare module '@mui/material/Button' {
  interface ButtonPropsSizeOverrides {
    largeWidth: true;
  }
  interface ButtonPropsVariantOverrides {
    disabled: true;
  }
  interface ButtonPropsSizeOverrides {
    extraSmall: true;
  }
  interface ButtonPropsVariantOverrides {
    containedSmall: true;
  }
  interface ButtonPropsVariantOverrides {
    btnOrange: true;
  }
  interface ButtonPropsColorOverrides {
    cgrey: true;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    default: true;
  }
  interface ButtonPropsSizeOverrides {
    smallest: true;
  }
}

declare module '@mui/material/IconButton' {
  interface IconButtonPropsSizeOverrides {
    smallest: true;
  }
}

declare module '@mui/material/FormControl' {
  interface FormcontrolPropsVariantOverrides {
    outlined: true;
  }
}

declare module '@mui/material/FormControlLabel' {
  interface FormControlLabelPropsVariantOverrides {
    color: true;
  }
}

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    cvContainer: true;
    xlg: true;
    xl: true;
    cvContainerXL: true;
    xxl: true;
  }
}

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      cvContainer: 1368,
      xlg: 1400,
      xl: 1600,
      cvContainerXL: 1768,
      xxl: 1800,
    },
  },
  typography: {
    fontFamily: 'Work Sans, sans-serif',
    fontSize: 16,
    button: {
      fontSize: 16,
      textTransform: 'none',
      '@media (max-width:1600px)': {
        fontSize: 14,
      },
    },
    body1: {
      fontSize: 18,
      fontWeight: 400,
      '@media (max-width:1600px)': {
        fontSize: 16,
      },
    },
    body2: {
      fontSize: 16,
      fontWeight: 500,
      '@media (max-width:1600px)': {
        fontSize: 14,
      },
    },
    subtitle1: {
      fontSize: 14,
      fontWeight: 500,
      '@media (max-width:1600px)': {
        fontSize: 12,
      },
    },
    subtitle2: {
      fontSize: 12,
      fontWeight: 400,
      '@media (max-width:1600px)': {
        fontSize: 10,
      },
    },
    h1: {
      fontSize: 30,
      fontWeight: 600,
      marginBottom: '30px',
      '@media (max-width:1800px)': {
        fontSize: '26px',
        marginBottom: '25px',
      },
      '@media (max-width:1600px)': {
        fontSize: '24px',
        marginBottom: '20px',
      },
    },
    h2: {
      fontSize: 26,
      fontWeight: 500,
      marginBottom: '30px',
      '@media (max-width:1800px)': {
        fontSize: '24px',
        marginBottom: '25px',
      },
      '@media (max-width:1600px)': {
        fontSize: '22px',
        marginBottom: '20px',
      },
      '&.h1': {
        fontSize: 30,
        fontWeight: 600,
        marginBottom: '6px',
        '@media (max-width:1800px)': {
          fontSize: '26px',
          marginBottom: '4px',
        },
        '@media (max-width:1600px)': {
          fontSize: '24px',
          marginBottom: '2px',
        },
      },
    },
    h3: {
      fontSize: 24,
      fontWeight: 600,
      marginBottom: '30px',
      '@media (max-width:1800px)': {
        fontSize: '22px',
        marginBottom: '25px',
      },
      '@media (max-width:1600px)': {
        fontSize: '20px',
        marginBottom: '20px',
      },
    },
    h4: {
      fontSize: 20,
      fontWeight: 600,
      marginBottom: '30px',
      '@media (max-width:1800px)': {
        fontSize: '18px',
        marginBottom: '25px',
      },
      '@media (max-width:1600px)': {
        fontSize: '16px',
        marginBottom: '20px',
      },
    },
    h5: {
      fontSize: 20,
      fontWeight: 500,
      marginBottom: '30px',
      '@media (max-width:1800px)': {
        fontSize: '18px',
        marginBottom: '25px',
      },
      '@media (max-width:1600px)': {
        fontSize: '16px',
        marginBottom: '20px',
      },
    },
    h6: {
      fontSize: 18,
      fontWeight: 500,
      marginBottom: '30px',
      '@media (max-width:1800px)': {
        fontSize: '16px',
        marginBottom: '25px',
      },
      '@media (max-width:1600px)': {
        fontSize: '14px',
        marginBottom: '20px',
      },
    },
  },
  palette: {
    primary: {
      main: '#0076FF',
      dark: '#071B34',
      light: '#F3F6FD',
    },
    secondary: {
      main: '#787A7C',
      dark: '#D6DADF',
      light: '#E2E0E0',
    },
    error: {
      main: '#E3281C',
    },
    warning: {
      main: '#FF7F21',
      contrastText: '#fff',
    },
    success: {
      main: '#5ABF66',
    },
    info: {
      main: '#FFC531',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          whiteSpace: 'nowrap',
          borderRadius: '10px',
          ':hover': {
            boxShadow: 'none',
          },
          '@media (max-width:1600px)': {
            borderRadius: '8px',
          },
          '@media (max-width:900px)': {
            borderRadius: '6px',
          },
          'svg.MuiSvgIcon-root': {
            fontSize: '24px',
            '@media (max-width:1800px)': {
              fontSize: '22px',
            },
            '@media (max-width:1600px)': {
              fontSize: '20px',
            },
            '@media (max-width:1200px)': {
              fontSize: '18px',
            },
            '@media (max-width:900px)': {
              fontSize: '16px',
            },
          },
          '&.Mui-disabled': {
            'svg *, path': {
              stroke: 'rgba(0, 0, 0, 0.26)',
            },
          },
        },
      },
      variants: [
        {
          props: { variant: 'default' },
          style: {
            border: '1px solid #E2E0E0',
            backgroundColor: '#ffffff',
          },
        },
        {
          props: { size: 'large' },
          style: {
            padding: '9px 30px',
            fontSize: '20px',
            '@media (max-width:1800px)': {
              padding: '8px 25px',
              fontSize: '18px',
            },
            '@media (max-width:1600px)': {
              padding: '8px 20px',
              fontSize: '16px',
            },
            '@media (max-width:900px)': {
              padding: '5px 20px',
              fontSize: '14px',
            },
          },
        },
        {
          props: { size: 'medium' },
          style: {
            padding: '10px 20px',
            fontSize: '16px',
            minWidth: '114px',
            // '.MuiButton-startIcon': {
            //   fontSize: '20px'
            // },
            '@media (max-width:1600px)': {
              padding: '8px 18px',
              fontSize: '14px',
              minWidth: '100px',
            },
            '@media (max-width:900px)': {
              padding: '6px 15px',
              fontSize: '12px',
              minWidth: '80px',
            },
          },
        },
        {
          props: { variant: 'contained' },
          style: {
            border: '1px solid transparent',
          },
        },
        {
          props: { size: 'small' },
          style: {
            borderRadius: '5px',
            padding: '5px 18px',
            '@media (max-width:1600px)': {
              padding: '4px 15px',
              fontSize: '14px',
            },
            '@media (max-width:900px)': {
              fontSize: '12px',
              padding: '3px 12px',
            },
          },
        },
        {
          props: { size: 'smallest' },
          style: {
            padding: '5px 10px',
            fontSize: 14,
            lineHeight: 1,
            fontWeight: 500,
            minWidth: 0,
            borderRadius: '5px',
            minHeight: '30px',
            '&.MuiButton-outlined': {
              backgroundColor: '#F3F6FD',
            },
            '.MuiButton-endIcon,.MuiButton-startIcon': {
              svg: {
                fontSize: '14px',
                '@media (max-width:1200px)': {
                  fontSize: '10px',
                },
              },
            },
            '@media (max-width:1600px)': {
              padding: '5px 9px',
              fontSize: '12px',
              minHeight: '25px',
              borderRadius: '4px',
            },
            '@media (max-width:900px)': {
              padding: '5px 7px',
              fontSize: '10px',
              minHeight: '20px',
              borderRadius: '3px',
            },
          },
        },
        {
          props: { variant: 'text' },
          style: {
            padding: '0',
            '@media (max-width:1600px)': {
              padding: '0',
            },
            '@media (max-width:900px)': {
              padding: '0',
            },
            '.MuiTouchRipple-root': {
              display: 'none',
            },
            ':hover': {
              color: '#071B34',
              background: 'transparent',
            },
          },
        },
      ],
    },
    MuiIconButton: {
      variants: [
        {
          props: { size: 'smallest' },
          style: {
            padding: '6px',
            fontSize: 10,
            lineHeight: 1,
            fontWeight: 500,
            '.MuiSvgIcon-root': {
              fontSize: 'inherit',
            },
          },
        },
      ],
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '10px',
          '@media (max-width:1600px)': {
            borderRadius: '8px',
          },
          '@media (max-width:900px)': {
            borderRadius: '6px',
          },
          color: '#9C9BAA',
          '& .MuiOutlinedInput-notchedOutline': {
            [`& .${outlinedInputClasses.notchedOutline}`]: {
              borderRadius: '10px',
              '@media (max-width:1600px)': {
                borderRadius: '8px',
              },
              '@media (max-width:900px)': {
                borderRadius: '6px',
              },
            },
          },
          '&.MuiInputBase-multiline': {
            padding: '15px 20px',
            '@media (max-width:1600px)': {
              padding: '12px 17px',
            },
            '@media (max-width:900px)': {
              padding: '7.5px 15px',
            },
            textarea: {
              color: '#071B34',
            },
          },
          input: {
            color: '#071B34',
            padding: '15px 20px',
            '@media (max-width:1600px)': {
              padding: '12px 17px',
            },
            '@media (max-width:900px)': {
              padding: '7.5px 15px',
            },
          },
          ':hover': {
            'fieldset.MuiOutlinedInput-notchedOutline': {
              borderColor: '#787A7C',
            },
          },
          fieldset: {
            borderColor: '#E2E0E0',
            color: '#9C9BAA',
          },
          '.MuiSelect-select': {
            padding: '15px 20px',
            '@media (max-width:1600px)': {
              padding: '12px 17px',
            },
            '@media (max-width:900px)': {
              padding: '7.5px 15px',
            },
          },
          '.MuiSelect-icon': {
            fontSize: '34px',
            '@media (max-width:1800px)': {
              fontSize: '22px',
            },
            '@media (max-width:1600px)': {
              fontSize: '20px',
            },
            '@media (max-width:1200px)': {
              fontSize: '18px',
            },
          },
          '&.Mui-focused': {
            '.MuiSelect-select~fieldset.MuiOutlinedInput-notchedOutline,fieldset.MuiOutlinedInput-notchedOutline':
            {
              borderColor: '#787A7C',
            },
            '.MuiSelect-select': {
              color: '#071B34',
            },
          },
          '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
            borderColor: 'transparent',
          },
          '&.Mui-disabled:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'transparent',
          },
          '&.Mui-disabled': {
            backgroundColor: '#F8F8F8',
            input: {
              cursor: 'not-allowed',
            },
          },
          '&.Mui-disabled input': {
            WebkitTextFillColor: 'inherit',
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          marginBottom: '30px',
          width: '100%',
          '.MuiFormControl-root': {
            marginBottom: '0px',
          },
          '@media (max-width:1800px)': {
            marginBottom: '25px',
          },
          '@media (max-width:1600px)': {
            marginBottom: '20px',
          },
          '@media (max-width:900px)': {
            marginBottom: '15px',
          },
          '&.search-section': {
            '.Mui-focused': {
              '.MuiSvgIcon-root:not([data-testid="InfoIcon"])': {
                path: {
                  fill: '#071B34',
                },
              },
            },
          },
          '.input-file-upload-icon': {
            svg: {
              path: {
                stroke: '#787A7C',
              },
            },
          },
        },
      },
      variants: [
        {
          props: { variant: 'outlined' },
          style: {
            lineHeight: '19px',
            '.MuiInputBase-formControl.Mui-focused .MuiOutlinedInput-notchedOutline':
            {
              borderWidth: '1px',
            },
          },
        },
      ],
    },
    MuiFormLabel: {
      variants: [
        {
          props: { color: 'primary' },
          style: {
            color: '#071B34',
            fontWeight: '500',
            marginBottom: '10px',
            lineHeight: '19px',
            fontSize: '16px',
            '@media (max-width:1600px)': {
              marginBottom: '6px',
              fontSize: '14px',
            },
          },
        },
      ],
      styleOverrides: {
        root: {
          '.MuiFormLabel-asterisk': {
            color: '#E3281C',
            lineHeight: 'normal',
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          color: '#787A7C',
          marginLeft: '0px',
          '&.MuiFormControlLabel-labelPlacementEnd': {
            marginRight: '40px',
            marginBottom: '5px',
            marginTop: '5px',
            '@media (max-width:1600px)': {
              marginRight: '30px',
              marginBottom: '3px',
              marginTop: '3px',
            },
            '@media (max-width:900px)': {
              marginRight: '20px',
              marginBottom: '2px',
              marginTop: '2px',
            },
          },
          '.MuiRadio-root': {
            color: '#E2E0E0',
          },
          '.Mui-checked~.MuiFormControlLabel-label': {
            color: '#071B34',
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: 0,
          '~.MuiFormControlLabel-label': {
            marginLeft: '10px',
            fontSize: '16px',
            '@media (max-width:1600px)': {
              fontSize: '14px',
              marginLeft: '8px',
            },
            '@media (max-width:900px)': {
              marginLeft: '5px',
            },
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          padding: 0,
          '~.MuiFormControlLabel-label': {
            marginLeft: '15px',
            '@media (max-width:1600px)': {
              marginLeft: '10px',
            },
            '@media (max-width:900px)': {
              marginLeft: '8px',
            },
            '@media (max-width:600px)': {
              marginLeft: '5px',
            },
          },
          '&.Mui-focusVisible': {
            '.MuiTouchRipple-root': {
              color: '#0076FF',
            },
          },
        },
      },
    },
    MuiFab: {
      variants: [
        {
          props: { variant: 'extended' },
          style: {
            width: '55px',
            height: '55px',
            minWidth: 'auto',
            '@media (max-width:1600px)': {
              width: '47px',
              height: '47px',
              borderRadius: '8px',
            },
            '@media (max-width:900px)': {
              width: '38px',
              height: '38px',
              borderRadius: '6px',
            },
          },
        },
      ],
    },
    MuiPopover: {
      styleOverrides: {
        root: {
          '.MuiPaper-root': {
            '&::-webkit-scrollbar': {
              width: '7px',
            },

            /* Track */
            '&::-webkit-scrollbar-track': {
              background: '#E2E0E0',
              borderRadius: '10px',
            },

            /* Handle */
            '&::-webkit-scrollbar-thumb': {
              background: '#9C9BAA',
              borderRadius: '10px',
            },

            /* Handle on hover */
            '&::-webkit-scrollbar-thumb:hover': {
              background: '#9C9BAA',
            },
          },
          '&.select-dropdown': {
            '.MuiPaper-root': {
              maxHeight: '224px',
              width: '250px',
              boxShadow: 'rgb(120 122 124 / 20%) 0px 7px 15px',
              border: '1px solid rgb(226, 224, 224)',
              marginTop: '5px',
              borderRadius: '10px',
              color: 'rgb(156, 155, 170)',
            },
            '.MuiMenu-list': {
              color: '#9C9BAA',
              li: {
                minHeight: 'auto',
                whiteSpace: 'normal',
              },
            },
            '.MuiMenu-list .Mui-selected,.MuiMenu-list li:hover': {
              backgroundColor: '#F8F8F8',
              color: '#787A7C',
            },
          },
          '&.pop-hover': {
            maxWidth: 'initial',
            '.MuiPaper-root': {
              boxShadow: '0px 4px 11px #9C9BAA4D',
              borderRadius: '8px',
              '.MuiList-root': {
                padding: '0px',
                '.MuiMenuItem-root': {
                  padding: '12px 30px 12px 20px',
                  fontSize: '16px',
                  lineHeight: '19px',
                  minHeight: 'auto',
                  '@media (max-width:1600px)': {
                    padding: '8px 25px 8px 15px',
                    fontSize: '14px',
                  },
                  svg: {
                    marginRight: '15px',
                    '@media (max-width:1600px)': {
                      marginRight: '10px',
                    },
                  },
                },
                '.MuiDivider-root': {
                  marginTop: '0',
                  marginBottom: '0',
                },
              },
            },
          },
          '&.notification-section': {},
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: '28',
          height: '16',
          padding: '0',
          display: 'flex',
          '&:active': {
            '& .MuiSwitch-thumb': {
              width: '15',
            },
            '& .MuiSwitch-switchBase.Mui-checked': {
              transform: 'translateX(9px)',
            },
          },
          '& .MuiSwitch-switchBase': {
            padding: 2,
            '&.Mui-checked': {
              transform: 'translateX(12px)',
              color: '#fff',
              '& + .MuiSwitch-track': {
                opacity: 1,
              },
            },
          },
          '& .MuiSwitch-thumb': {
            boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
            width: '12',
            height: '12',
            borderRadius: '6',
          },
          '& .MuiSwitch-track': {
            borderRadius: 16 / 2,
            opacity: 1,
            boxSizing: 'border-box',
          },
          '.MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(17px)',
          },
        },
      },
    },
    MuiPagination: {
      styleOverrides: {
        root: {
          width: '100%',
          ul: {
            li: {
              button: {
                border: 'none',
              },
            },
          },
          '.MuiPaginationItem-text': {
            height: '40px',
            minWidth: '40px',
            color: '#787A7C',
            borderRadius: '5px',
            margin: '0px 5px',
            fontSize: '16px',
            '@media (max-width:1600px)': {
              height: '35px',
              minWidth: '35px',
              fontSize: '14px',
            },
            '@media (max-width:900px)': {
              height: '30px',
              minWidth: '30px',
            },
            '.MuiTypography-root': {
              fontSize: '16px',
              fontWeight: '500',
              '@media (max-width:1600px)': {
                fontSize: '14px',
              },
            },
            '&.Mui-selected': {
              backgroundColor: '#F3F6FD',
              color: '#0076FF',
            },
            '&.MuiPaginationItem-ellipsis': {
              margin: '0px 15px',
              lineHeight: '40px',
            },
            '.text': {
              verticalAlign: 'middle',
              margin: '0 10px',
            },
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          color: '#071b34',
          '&.MuiPaper-outlined': {
            borderRadius: '8px',
            padding: '30px 25px',
            borderColor: '#E2E0E0',
            '@media (max-width:1600px)': {
              padding: '25px 20px',
            },
            '@media (max-width:900px)': {
              padding: '20px 15px',
              borderRadius: '5px',
            },
          },
          '&.paper-box': {
            padding: '20px',
            '@media (max-width:1600px)': {
              padding: '15px',
            },
            '@media (max-width:900px)': {
              padding: '10px',
            },
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          '&.MuiAutocomplete-tag,&.MuiChip-root': {
            padding: '10px',
            borderRadius: '5px',
            fontSize: '14px',
            margin: '0 15px 10px 0',
            height: 'auto',
            fontWeight: '500',
            '@media (max-width:1600px)': {
              margin: '0 10px 5px 0',
              padding: '8px',
            },
            '@media (max-width:900px)': {
              padding: '5px 8px',
              fontSize: '12px',
              margin: '0 8px 5px 0',
            },
            '.MuiChip-label': {
              padding: '0px',
              marginRight: '0',
              lineHeight: '16px',
              wordBreak: 'break-word',
              whiteSpace: 'break-spaces',
              '@media (max-width:1600px)': {
                lineHeight: 'normal',
              },
            },
            '.MuiSvgIcon-root, & > svg': {
              fontSize: '14px',
              margin: '0 0 0 7px',
              width: '14px',
              height: '14px',
              path: {
                stroke: '#fff',
                strokeWidth: '3',
              },
              '&:hover': {
                color: '#ffffffb0',
              },
              '@media (max-width:900px)': {
                fontSize: '12px',
              },
            },
          },
        },
      },
      variants: [
        {
          props: { color: 'default' },
          style: {
            backgroundColor: '#F3F6FD',
            color: '#0076FF',
            margin: '15px 15px 0px 0',
            ':hover': {
              backgroundColor: '#e3ecff',
            },
            '@media (max-width:1600px)': {
              margin: '10px 10px 0px 0',
            },
            '.MuiSvgIcon-root': {
              color: '#0076FF',
              ':hover': {
                color: '#0076FF',
              },
            },
          },
        },
      ],
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: '#E2E0E0',
        },
      },
    },
    MuiModal: {
      styleOverrides: {
        root: {
          '.MuiBox-root': {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            padding: '40px',
            '@media (max-width:1800px)': {
              padding: '30px',
              borderRadius: '8px',
            },
            '@media (max-width:1600px)': {
              padding: '20px',
            },
            '@media (max-width:600px)': {
              padding: '15px',
              borderRadius: '5px',
            },
          },
          '.MuiBox-root,.MuiDialog-container>.MuiPaper-root': {
            backgroundColor: '#fff',
            boxShadow: '0px 6px 16px #787A7C4D',
            border: '1px solid #E2E0E0',
            borderRadius: '10px',
            overflow: 'visible',
            '@media (max-width:1800px)': {
              borderRadius: '8px',
            },
            '@media (max-width:600px)': {
              borderRadius: '5px',
            },
            '.MuiDialogTitle-root': {
              padding: '40px 40px 0 40px',
              margin: 0,
              '@media (max-width:1800px)': {
                padding: '30px 30px 0 30px',
              },
              '@media (max-width:1600px)': {
                padding: '20px 20px 0 20px',
              },
              '@media (max-width:600px)': {
                padding: '15px 15px 0 15px',
              },
            },
            '.MuiDialogContent-root': {
              padding: '30px 40px',
              color: '#071B34',
              '@media (max-width:1800px)': {
                padding: '20px 30px',
              },
              '@media (max-width:1600px)': {
                padding: '20px',
              },
              '@media (max-width:600px)': {
                padding: '15px',
              },
              '.MuiDialogContentText-root': {
                color: '#071B34',
                fontWeight: 400,
                lineHeight: '23px',
              },
            },
            '.MuiDialogActions-root': {
              padding: '0 40px 30px 40px',
              flexWrap: 'wrap',
              '@media (max-width:1800px)': {
                padding: '0 30px 30px 30px',
              },
              '@media (max-width:1600px)': {
                padding: '0 20px 20px 20px',
              },
              '@media (max-width:600px)': {
                padding: '0 15px 15px 15px',
              },
            },
            '.close-icon': {
              position: 'absolute',
              top: '-20px',
              right: '-20px',
              backgroundColor: '#fff',
              color: '#787A7C',
              height: '50px',
              width: '50px',
              boxShadow: '0px 0px 10px #787A7C4D',
              border: '1px solid #E2E0E0',
              '&:hover': {
                backgroundColor: '#f5f5f5',
              },
              '@media (max-width:1800px)': {
                height: '45px',
                width: '45px',
              },
              '@media (max-width:1600px)': {
                top: '-15px',
                right: '-15px',
                height: '40px',
                width: '40px',
              },
              '@media (max-width:900px)': {
                height: '38px',
                width: '38px',
              },
            },
          },
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fontSize: '24px',
          '@media (max-width:1800px)': {
            fontSize: '22px',
          },
          '@media (max-width:1600px)': {
            fontSize: '20px',
          },
          '@media (max-width:1200px)': {
            fontSize: '18px',
          },
          '@media (max-width:900px)': {
            fontSize: '16px',
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          '&.Mui-error': {
            marginLeft: 0,
            marginRight: 0,
            width: '100%',
          },
        },
      },
    },
    MuiStepper: {
      styleOverrides: {
        root: {
          '.MuiStep-root': {
            textAlign: 'center',
            '.MuiStepConnector-root': {
              top: '35px',
              '@media (max-width:1800px)': {
                top: '30px',
              },
              '@media (max-width:1600px)': {
                top: '26px',
              },
              '@media (max-width:900px)': {
                top: '20px',
              },
              '&.Mui-active, &.Mui-completed': {
                '.MuiStepConnector-line': {
                  borderColor: '#0076FF',
                },
              },
              '.MuiStepConnector-line': {
                borderColor: '#E2E0E0',
                borderWidth: '3px',
                '@media (max-width:1600px)': {
                  borderWidth: '2px',
                },
                '@media (max-width:1200px)': {
                  borderWidth: '1px',
                },
              },
            },
            '.MuiButtonBase-root': {
              width: 'auto',
            },
          },
          '.MuiStepLabel-iconContainer': {
            '&.MuiStepLabel-alternativeLabel': {
              border: '3px solid #E2E0E0',
              borderRadius: '50%',
              padding: '17px',
              backgroundColor: '#fff',
              zIndex: 1,
              '@media (max-width:1800px)': {
                border: '2px solid #E2E0E0',
                padding: '15px',
              },
              '@media (max-width:1600px)': {
                border: '1px solid #E2E0E0',
                padding: '13px',
              },
              '@media (max-width:900px)': {
                padding: '10px',
              },
              '&.Mui-active': {
                borderColor: '#0076FF',
                svg: {
                  path: {
                    stroke: '#0076FF',
                  },
                },
              },
              '&.Mui-completed': {
                borderColor: '#0076FF',
                backgroundColor: '#0076FF',
                svg: {
                  path: {
                    stroke: '#fff',
                  },
                },
              },
              svg: {
                fontSize: '30px',
                '@media (max-width:1800px)': {
                  fontSize: '25px',
                },
                '@media (max-width:1600px)': {
                  fontSize: '20px',
                },
                '@media (max-width:900px)': {
                  fontSize: '18px',
                },
                path: {
                  stroke: '#9C9BAA',
                },
              },
            },
          },
          '.MuiStepLabel-labelContainer': {
            '.MuiStepLabel-label': {
              fontSize: '20px',
              marginTop: '10px',
              color: '#9C9BAA',
              lineHeight: '24px',
              '@media (max-width:1800px)': {
                fontSize: '18px',
                lineHeight: '22px',
              },
              '@media (max-width:1600px)': {
                fontSize: '16px',
                lineHeight: '20px',
              },
              '@media (max-width:900px)': {
                fontSize: '14px',
                lineHeight: '18px',
              },
              '&.Mui-active,&.Mui-completed': {
                color: '#0076FF',
              },
            },
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          marginBottom: '-1px',
          minHeight: 'auto',
          '&.tabs~.tabs-panel-wrapper,&.tabs~div .tabs-panel-wrapper': {
            borderTopLeftRadius: '0',
            borderTopRightRadius: '0',
          },
          '.MuiTabs-scrollButtons': {
            '&.Mui-disabled': {
              opacity: '0.3',
            }
          },
          '.MuiTab-root': {
            fontSize: '20px',
            borderRadius: '10px 10px 0 0',
            color: '#787A7C',
            backgroundColor: '#E2E0E0',
            border: '1px solid #E2E0E0',
            borderBottom: '0',
            borderTop: '0',
            margin: '0 5px 0 0',
            padding: '18px 29px',
            lineHeight: '24px',
            minHeight: 'auto',
            '@media (max-width:1800px)': {
              padding: '13px 25px',
              fontSize: '18px',
              lineHeight: '22px',
            },
            '@media (max-width:1600px)': {
              padding: '12px 22px',
              fontSize: '16px',
              lineHeight: '20px',
              borderRadius: '8px 8px 0 0',
            },
            '@media (max-width:1200px)': {
              padding: '10px 20px',
            },
            '@media (max-width:900px)': {
              padding: '8px 15px',
              fontSize: '14px',
              lineHeight: '18px',
              borderRadius: '5px 5px 0 0',
            },
            '&.Mui-selected': {
              backgroundColor: '#fff',
              color: '#071B34',
              fontWeight: '600',
              '&:after': {
                display: 'block',
              },
            },
            '&:after': {
              content: '""',
              position: 'absolute',
              top: '0px',
              width: '100%',
              background: '#0076FF',
              height: '4px',
              display: 'none',
              '@media (max-width:1800px)': {
                height: '3px',
              },
              '@media (max-width:1200px)': {
                height: '2px',
              },
            },
          },
          '.MuiTabs-indicator': {
            display: 'none',
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          border: '1px solid #E2E0E0',
          borderRadius: '8px',
          boxShadow: 'none',
          '&:first-of-type': {
            borderRadius: '8px',
          },
          '&:last-of-type': {
            borderRadius: '8px',
          },
          '&.Mui-expanded': {
            margin: '0',
            borderColor: '#9C9BAA',
          },
          '&+.MuiAccordion-root': {
            marginTop: '10px',
          },
          '&:after,&:before': {
            display: 'none',
          },
          '.MuiAccordionSummary-root': {
            padding: '16px 20px',
            color: '#071B34',
            minHeight: 'auto',
            '@media (max-width:1600px)': {
              padding: '14px 15px',
            },
            '@media (max-width:1200px)': {
              padding: '12px',
            },
            '&.Mui-expanded': {
              minHeight: 'auto',
            },
            '.MuiAccordionSummary-content': {
              margin: '0',
              '.MuiTypography-root': {
                lineHeight: '19px',
              },
            },
            '.MuiAccordionSummary-expandIconWrapper': {
              svg: {
                fontSize: '14px',
                '@media (max-width:900px)': {
                  fontSize: '10px',
                },
                '*': {
                  stroke: '#071B34',
                },
              },
              '&.Mui-expanded': {
                svg: {
                  path: {
                    d: 'path("M 622 1401 m -9 9 h 18")',
                  },
                },
              },
            },
          },
          '.MuiCollapse-root': {
            '.MuiAccordionDetails-root': {
              padding: '0',
              ul: {
                padding: '0 20px 0 30px',
                '@media (max-width:1600px)': {
                  padding: '0 15px 0 25px',
                },
                '@media (max-width:1200px)': {
                  padding: '0 10px 0 20px',
                },
                li: {
                  padding: '15px 0',
                  justifyContent: 'space-between',
                  '@media (max-width:1600px)': {
                    padding: '10px 0',
                  },
                  '@media (max-width:1200px)': {
                    padding: '8px 0',
                  },
                  '.MuiTypography-root': {
                    fontWeight: 400,
                    paddingRight: '10px',
                    lineHeight: '19px',
                  },
                  '&:first-of-type': {
                    paddingTop: '4px',
                  },
                  '.MuiButtonBase-root': {
                    flex: 'none',
                  },
                },
              },
            },
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '.MuiAutocomplete-endAdornment': {
            top: '50%',
            transform: 'translateY(-50%)',
          },
          '.MuiInputBase-root': {
            padding: '15px 20px',
            '@media (max-width:1600px)': {
              padding: '12px 17px',
            },
            '@media (max-width:900px)': {
              padding: '7.5px 15px',
            },
            '.MuiInputBase-input ': {
              padding: '0',
            },
          },
          '.MuiAutocomplete-popupIndicator': {
            svg: {
              fontSize: '34px',
              '@media (max-width:1800px)': {
                fontSize: '22px',
              },
              '@media (max-width:1600px)': {
                fontSize: '20px',
              },
              '@media (max-width:1200px)': {
                fontSize: '18px',
              },
            },
          },
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          height: '20px',
          borderRadius: '18px',
          backgroundColor: '#F3F6FD',
          margin: '20px 0',
          '@media (max-width:1600px)': {
            height: '15px',
            borderRadius: '15px',
            margin: '15px 0',
          },
          '@media (max-width:1200px)': {
            height: '10px',
            borderRadius: '10px',
            margin: '10px 0',
          },
          '@media (max-width:900px)': {
            height: '8px',
            borderRadius: '8px',
            margin: '10px 0',
          },
          '.MuiLinearProgress-bar': {
            borderRadius: '18px',
          },
        },
      },
    },
  },
});
