import React from 'react';
import { Alert, AlertTitle } from '@mui/material';

const statusMessage = (message: string): JSX.Element => {
  return (
    <Alert severity='warning' sx={{ mt: 3 }}>
      <AlertTitle>Warning</AlertTitle>
      {message}
    </Alert>
  );
};
export default statusMessage;
