
import React from 'react';
const CvTemplate = (): JSX.Element => {
  return (
    <svg width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">

        <defs>
          <clipPath id='clip-Team'>
            <rect width='24' height='24' />
          </clipPath>
        </defs>
        <g id='Team' clipPath='url(#clip-Team)'>
            <path fill="none" stroke="#000"  d="M1,3 L23,3 L23,21 L1,21 L1,3 Z M1,8 L23,8 M7,8 L7,21" />

        </g>
      </svg>
  );
};
export default CvTemplate;
