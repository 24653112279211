import { localStorageKeys } from '@config/constant';
import { createSlice } from '@reduxjs/toolkit';
import mainLogo from '../../../assets/images/logo.svg';

export interface ICandidateRedux {
  organizationLogo: string;
}

const initialState = {
  organizationLogo: sessionStorage.getItem(localStorageKeys.organizationLogo) || mainLogo
}


export const candidateSlice = createSlice({
  name: 'candidate',
  initialState,
  reducers: {
    storeCandidate: (state, action) => {
      state.organizationLogo = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { storeCandidate } = candidateSlice.actions;

export default candidateSlice.reducer;
