import React from 'react';

const CvJobs = (): JSX.Element => {
  return (
    <>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='24'
        height='24'
        viewBox='0 0 24 24'
      >
        <defs>
          <clipPath id='clip-Jobs'>
            <rect width='24' height='24' />
          </clipPath>
        </defs>
        <g id='Jobs' clipPath='url(#clip-Jobs)'>
          <g
            id='Group_11987'
            data-name='Group 11987'
            transform='translate(-477.198 -441.047)'
          >
            <line
              id='Line_4'
              data-name='Line 4'
              x1='6'
              transform='translate(484.047 452.047)'
              fill='none'
              stroke='#9c9baa'
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth='2'
            />
            <line
              id='Line_5'
              data-name='Line 5'
              x1='2'
              transform='translate(484.047 456.047)'
              fill='none'
              stroke='#9c9baa'
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth='2'
            />
            <line
              id='Line_6'
              data-name='Line 6'
              x1='8'
              transform='translate(484.047 448.047)'
              fill='none'
              stroke='#9c9baa'
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth='2'
            />
            <g
              id='Group_11984'
              data-name='Group 11984'
              transform='translate(479 442)'
            >
              <path
                id='Path_15358'
                data-name='Path 15358'
                d='M17.047,9.547v-3.7a7.378,7.378,0,0,0-.33-3.16,2.876,2.876,0,0,0-1.31-1.31,7.378,7.378,0,0,0-3.16-.33h-6.4a7.378,7.378,0,0,0-3.16.33,2.876,2.876,0,0,0-1.31,1.31,7.378,7.378,0,0,0-.33,3.16v10.4a7.378,7.378,0,0,0,.33,3.16,2.876,2.876,0,0,0,1.31,1.31,7.378,7.378,0,0,0,3.16.33h2.7'
                fill='none'
                stroke='#9c9baa'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='2'
              />
              <circle
                id='Ellipse_43'
                data-name='Ellipse 43'
                cx='1.75'
                cy='1.75'
                r='1.75'
                transform='translate(13.673 12.297)'
                fill='none'
                stroke='#9c9baa'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='2'
              />
              <path
                id='Path_15359'
                data-name='Path 15359'
                d='M11.448,21.094v-.709A2.151,2.151,0,0,1,13.6,18.234h3.647A2.151,2.151,0,0,1,19.4,20.385v.709'
                fill='none'
                stroke='#9c9baa'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='2'
              />
            </g>
          </g>
        </g>
      </svg>
    </>
  );
};
export default CvJobs;
