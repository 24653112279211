import React from 'react';

const CvCompany = (): JSX.Element => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
    >
      <defs>
        <clipPath id='clip-Company'>
          <rect width='24' height='24' />
        </clipPath>
      </defs>
      <g id='Company' clipPath='url(#clip-Company)'>
        <path
          id='Path_2517'
          data-name='Path 2517'
          d='M71.5,887.889H68.6a2.253,2.253,0,0,0-1.054.121,1.064,1.064,0,0,0-.437.486A3.048,3.048,0,0,0,67,889.667V899m13.5-11.111h2.9a2.253,2.253,0,0,1,1.054.121,1.064,1.064,0,0,1,.437.486A3.048,3.048,0,0,1,85,889.667V899m-4.5,0V882.556a6.1,6.1,0,0,0-.218-2.342,2.128,2.128,0,0,0-.874-.971A4.505,4.505,0,0,0,77.3,879H74.7a4.505,4.505,0,0,0-2.108.242,2.128,2.128,0,0,0-.874.971,6.1,6.1,0,0,0-.218,2.342V899M86,899H66m9-15.556h2m-2,4.444h2m-2,4.444h2'
          transform='translate(-63.5 -876.5)'
          fill='none'
          stroke='#9c9baa'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='2'
        />
      </g>
    </svg>
  );
};
export default CvCompany;
