import React from 'react';

const CvUser = (): JSX.Element => {
  return (
    <>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='24'
        height='24'
        viewBox='0 0 24 24'
      >
        <defs>
          <clipPath id='clip-Candidate'>
            <rect width='24' height='24' />
          </clipPath>
        </defs>
        <g id='Candidate' clipPath='url(#clip-Candidate)'>
          <path
            id='Path_3014'
            data-name='Path 3014'
            d='M1358,1727a11.1,11.1,0,0,0-.191-2.956,4.486,4.486,0,0,0-3-2.967,11.522,11.522,0,0,0-2.992-.189h-5.625a11.522,11.522,0,0,0-2.993.189,4.486,4.486,0,0,0-3,2.967A11.1,11.1,0,0,0,1340,1727m14.063-15a5.063,5.063,0,1,1-5.062-5A5.025,5.025,0,0,1,1354.063,1712Z'
            transform='translate(-1337 -1705)'
            fill='none'
            stroke='#9c9baa'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='2'
          />
        </g>
      </svg>
    </>
  );
};
export default CvUser;
