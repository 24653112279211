import {
  KEYS,
  OrganizationMasterUUID,
  ROUTES,
  localStorageKeys,
} from '@config/constant';
import { getAdminAuthToken, getAuthToken } from '@config/helpers';
import { Dayjs } from 'dayjs';
import React from 'react';
import moment from 'moment';

export const checkIfAdminFromSlug = () => {
  const currSlug = window.location.pathname;
  const slugArray = currSlug.split('/');
  const isAdmin = slugArray.find((element) => element === ROUTES.admin);
  return isAdmin;
};

export const verifyAuth = () => {
  return getAuthToken() !== null ? true : false;
};

export const verifyAdminAuth = () => {
  return getAdminAuthToken() !== null ? true : false;
};

export const destroyAuth = () => {
  localStorage.removeItem(KEYS.userInfo);
};

export const destroyAdminAuth = () => {
  localStorage.removeItem(KEYS.adminInfo);
};

export const generateDateFormatAsPerAPI = (fromDate: Dayjs, toDate: Dayjs) => {
  const generatedFromDate = `${fromDate?.year()}-${
    fromDate?.month() + 1
  }-${fromDate?.date()}`;
  const generatedToDate = `${toDate?.year()}-${
    toDate?.month() + 1
  }-${toDate?.date()}`;
  return { generatedFromDate, generatedToDate };
};

/**
 * Get Candidate UUID from local storage
 */
export const getCandidateUUIDAnswer = () => {
  return sessionStorage.getItem(localStorageKeys.candidateUUIDForAnswer);
};

export const removeCandidateUUIDAnswer = () => {
  return sessionStorage.removeItem(localStorageKeys.candidateUUIDForAnswer);
};

/**
 * @name : text ellipsis
 * @param str
 * @param limit
 * @description :  truncate a text or line with ellipsis
 */
export const TextTruncate = (str: string, limit: number) => {
  return str?.length > limit ? `${str.substring(0, limit)}...` : str;
};

export const secondsToTime = (e: number) => {
  const h = Math.floor(e / 3600)
      .toString()
      .padStart(2, '0'),
    m = Math.floor((e % 3600) / 60)
      .toString()
      .padStart(2, '0'),
    s = Math.floor(e % 60)
      .toString()
      .padStart(2, '0');
  if (e <= -1) {
    return '00:00';
  }
  return h !== '00' ? h + ':' : +m + ':' + s;
};

/**
 * Compare Value with array value and nearest value index (position) return
 * @param arr
 * @param target value
 * @returns
 */
export const findNearestIndex = (arr: number[], target: number) => {
  let minDiff = Infinity;
  let nearestIndex = null;

  for (let i = 0; i < arr.length; i++) {
    const diff = Math.abs(arr[i] - target);
    if (diff < minDiff) {
      minDiff = diff;
      nearestIndex = i;
    }
  }

  return nearestIndex;
};

/**
 * Find Object from the array and move it to the first element
 */
export const moveElementToFirst = (array: object[], element: object) => {
  // Find the index of the element
  const index = array?.findIndex((obj) => obj === element);
  if (index !== -1) {
    // Remove the element from its current position
    array.splice(index, 1);

    // Insert the element at the beginning of the array
    array.unshift(element);
    return array;
  } else {
    return array;
  }
};

export const getGridSize = (value: string) => {
  return value ? 8 : 12;
};

export const getFileSelectedClassName = (value: string) => {
  return value ? 'file-selected' : '';
};

export const getValueOrNull = (element: JSX.Element[]) => {
  return element ? element : null;
};

export const getDisabledClassName = (value: boolean) => {
  return value ? 'video-disabled' : '';
};

export const getListDraggableClassName = (value: boolean | undefined) => {
  return value ? 'list-draggable' : '';
};

export const checkIfMasterData = (value: string) => {
  return value === OrganizationMasterUUID ? 'Master Data' : value;
};
/**
 * Convert String first letter to capital & other all are in small
 * @param data
 * @returns
 */
export const covertFirstCapitalLetter = (data: string) => {
  if (data) {
    return data.charAt(0).toUpperCase() + data.slice(1).toLowerCase();
  }
  return '';
};

/**
 * Generate random number insted of math.random() function
 * @returns number
 */
export const generateSecureRandomNumber = () => {
  const array = new Uint32Array(1);
  if (window?.crypto) {
    window.crypto.getRandomValues(array);
  }
  const randomNumber = array[0];
  return randomNumber;
};
/**
 * Keyboard number key only allowed
 * @param event
 */
export const onlyNumberAllowed = (event: React.KeyboardEvent) => {
  if (!/[\d]/.test(event.key)) {
    event.preventDefault();
  }
};

/**
 * Convert Date into PST time format
 * @param date 
 * @returns 
 */
export const convertDateToPST = (date: string) => {
  const currentDateTime = new Date(date);
  const utcDateTime = new Date(currentDateTime?.toUTCString());
  utcDateTime.setHours(utcDateTime.getHours() - 8);
  const usDate = moment(new Date(utcDateTime)).format('MM/DD/YYYY hh:mm a');
  return usDate;
};
