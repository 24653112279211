import React from 'react';

const CvCmsPages = (): JSX.Element => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19"
            height="22"
            viewBox="0 0 19 22"
        >
            <path
                id="Path_15076"
                data-name="Path 15076"
                d="M2027,4130.5v-3.7a7.374,7.374,0,0,0-.33-3.16,2.864,2.864,0,0,0-1.31-1.31,7.373,7.373,0,0,0-3.16-.33h-6.4a7.373,7.373,0,0,0-3.16.33,2.864,2.864,0,0,0-1.31,1.31,7.374,7.374,0,0,0-.33,3.16v10.4a7.374,7.374,0,0,0,.33,3.16,2.864,2.864,0,0,0,1.31,1.31,7.373,7.373,0,0,0,3.16.33h3.2m2-11h-6m2,4h-2m8-8h-8m10,14v-6m-3,3h6"
                transform="translate(-2010 -4121)"
                fill="none"
                stroke="#9c9baa"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
            />
        </svg>
    );
};
export default CvCmsPages;
