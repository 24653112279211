import { createSlice } from '@reduxjs/toolkit';
export interface RadioFilter {
  radioValue: string;
}

const initialState: RadioFilter = {
  radioValue: '',
};

export const filterDataSlice = createSlice({
  name: 'dateFilter',
  initialState,
  reducers: {
    storeFilterRadioValue: (state, action) => {
      state.radioValue = action.payload.radioValue;
    },
    clearRadioValue: (state) => {
      state.radioValue = initialState.radioValue;
    },
  },
});

// Action creators are generated for each case reducer function
export const { storeFilterRadioValue, clearRadioValue } =
  filterDataSlice.actions;

export default filterDataSlice.reducer;
