import { createSlice } from '@reduxjs/toolkit';
import { ImenuListMap } from 'src/interface/sidebar';

export interface MenuListState {
  list: Array<ImenuListMap>;
}

const initialState: MenuListState = {
  list: [],
};

export const menuListSlice = createSlice({
  name: 'menuList',
  initialState,
  reducers: {
    storeMenuList: (state, action) => {
      state.list = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { storeMenuList } = menuListSlice.actions;

export default menuListSlice.reducer;
