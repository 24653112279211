import React from 'react';

const CvSettings = (): JSX.Element => {
  return (
    <>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='24'
        height='24'
        viewBox='0 0 24 24'
      >
        <defs>
          <clipPath id='clip-Setting'>
            <rect width='24' height='24' />
          </clipPath>
        </defs>
        <g id='Setting' clipPath='url(#clip-Setting)'>
          <g
            id='Group_12266'
            data-name='Group 12266'
            transform='translate(23889.953 11379)'
          >
            <path
              id='Path_14040'
              data-name='Path 14040'
              d='M1016.4,2107.37l.58,1.32a2.16,2.16,0,0,0,.82.95,2.117,2.117,0,0,0,1.2.36,2.172,2.172,0,0,0,1.21-.36,2.138,2.138,0,0,0,.81-.95l.59-1.32a2.447,2.447,0,0,1,1-1.11,2.358,2.358,0,0,1,1.47-.31l1.43.15a2.159,2.159,0,0,0,1.24-.23,2.219,2.219,0,0,0,.91-.86,2.285,2.285,0,0,0,.29-1.23,2.192,2.192,0,0,0-.42-1.18l-.85-1.17a2.437,2.437,0,0,1-.46-1.43,2.471,2.471,0,0,1,.47-1.43l.85-1.16a2.31,2.31,0,0,0,.42-1.19,2.194,2.194,0,0,0-.3-1.22,2.245,2.245,0,0,0-.91-.87,2.206,2.206,0,0,0-1.24-.22l-1.43.15a2.516,2.516,0,0,1-1.47-.31,2.471,2.471,0,0,1-1-1.12l-.59-1.32a2.138,2.138,0,0,0-.81-.95,2.172,2.172,0,0,0-1.21-.36,2.117,2.117,0,0,0-1.2.36,2.16,2.16,0,0,0-.82.95l-.58,1.32a2.471,2.471,0,0,1-1,1.12,2.516,2.516,0,0,1-1.47.31l-1.44-.15a2.221,2.221,0,0,0-1.24.22,2.308,2.308,0,0,0-.91.87,2.272,2.272,0,0,0-.29,1.22,2.225,2.225,0,0,0,.42,1.19l.85,1.16a2.452,2.452,0,0,1,0,2.86l-.85,1.16a2.225,2.225,0,0,0-.42,1.19,2.272,2.272,0,0,0,.29,1.22,2.308,2.308,0,0,0,.91.87,2.221,2.221,0,0,0,1.24.22l1.43-.15a2.5,2.5,0,0,1,1.47.31A2.437,2.437,0,0,1,1016.4,2107.37Z'
              transform='translate(-24897 -13467)'
              fill='none'
              stroke='#9c9baa'
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth='2'
            />
            <path
              id='Path_14041'
              data-name='Path 14041'
              d='M1019,2103a3,3,0,1,0-3-3A3,3,0,0,0,1019,2103Z'
              transform='translate(-24897 -13467)'
              fill='none'
              stroke='#9c9baa'
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth='2'
            />
          </g>
        </g>
      </svg>
    </>
  );
};
export default CvSettings;
