import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { Elements } from '@stripe/react-stripe-js';

// import i18n (needs to be bundled ))
import './i18n';
import { client } from '@framework/graphql/apolloclient';

import { ThemeProvider } from '@mui/material';
import { theme } from './styles/theme';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import { loadStripe } from '@stripe/stripe-js';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLIC_KEY as string
);

root.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <ApolloProvider client={client}>
        <BrowserRouter>
          <Elements stripe={stripePromise}>
            <App />
          </Elements>
        </BrowserRouter>
      </ApolloProvider>
    </ThemeProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
