import React from 'react';

const CvReports = (): JSX.Element => {
  return (
    <>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='24'
        height='24'
        viewBox='0 0 24 24'
      >
        <defs>
          <clipPath id='clip-Report'>
            <rect width='24' height='24' />
          </clipPath>
        </defs>
        <g id='Report' clipPath='url(#clip-Report)'>
          <path
            id='Path_3778'
            data-name='Path 3778'
            d='M1350,3710.27v4.13a2.432,2.432,0,0,0,.11,1.05.938.938,0,0,0,.44.44,2.429,2.429,0,0,0,1.05.11h4.13m-11.73,7v3m8-5v5m-4-7.5v7.5m8-8.01v7.21a7.377,7.377,0,0,1-.33,3.16,2.867,2.867,0,0,1-1.31,1.31,7.373,7.373,0,0,1-3.16.33h-6.4a7.373,7.373,0,0,1-3.16-.33,2.867,2.867,0,0,1-1.31-1.31,7.377,7.377,0,0,1-.33-3.16v-10.4a7.377,7.377,0,0,1,.33-3.16,2.867,2.867,0,0,1,1.31-1.31,7.373,7.373,0,0,1,3.16-.33h3.21a6.675,6.675,0,0,1,1.45.08,3.258,3.258,0,0,1,.86.36,6.405,6.405,0,0,1,1.09.97l3.18,3.18a6.423,6.423,0,0,1,.97,1.09,3.269,3.269,0,0,1,.36.86A6.684,6.684,0,0,1,1356,3717.99Z'
            transform='translate(-1335.5 -3707.5)'
            fill='none'
            stroke='#9c9baa'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='2'
          />
        </g>
      </svg>
    </>
  );
};
export default CvReports;
